import Track from './Track.js';
export default class Tracks {
  trackRoot;
  ring = document.getElementById('ring');
  tracks;
  loop;
  constructor(payload) {
    this.loop = payload.loop;
    this.trackFactory();
  }
  get xyC(){
    return 300
  }
  get radius(){
    return 192
  }
  get dim(){
    return {
      l: '600',
      viewbox: '0 0 600 600' 
    };
  }

  trackFactory() {
    const trackContainer = document.createElement('div');
    trackContainer.style.width = '100%';
    trackContainer.style.height = '100%';
    trackContainer.style.zIndex = '2';
    trackContainer.id = 'trackContainer';
    trackContainer.style.position = 'absolute';
    trackContainer.style.top = '0';
    trackContainer.style.right = '0';

    const tracks = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    tracks.setAttribute('width', this.dim.l);
    tracks.setAttribute('height', this.dim.l);
    tracks.setAttribute('viewbox', this.dim.viewbox);
    tracks.setAttribute('preserveAspectRatio', 'none');
    tracks.setAttribute('id', 'tracks');
    trackContainer.appendChild(tracks);
    this.ring.appendChild(trackContainer);

    this.tracks = Snap('#tracks');
    let count = 0;

    this.loop.spine.forEach((keyValue) => {
      const payload = {
        loop: this.loop,
        emotion: keyValue[0],
        element: this.tracks,
        count: count,
        dim: this.dim,
        radius: this.radius
      };
      count++;
      this.makeTracks(payload)
    });
  }
  makeTracks(payload){
    new Track(payload);
  }
}
