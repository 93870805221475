import ImageServer from './ImageServer/ImageServer.js';

export default class QuadPage {
  doc;
  content;
  actorName;
  initX = 30;
  bodyFontSize = 10;
  scoreFontSize = 20;
  stretchingMe;
  connectingMe;
  protectingMe;
  meFirst;
  totalScore;
  quadPercentage;
  emojiWidth=45;
  imageServer = new ImageServer();

  constructor(payload) {
    this.totalScore = payload.loop.quadrantTotalScore;
    this.doc = payload.doc;
    this.stretchingMe = payload.loop.quadrant.stretchingMe;
    this.connectingMe = payload.loop.quadrant.connectingMe;
    this.protectingMe = payload.loop.quadrant.protectingMe;
    this.meFirst = payload.loop.quadrant.meFirst;
    this.quadPercentage = payload.loop.quadPercentage;
    this.quads();
  }

  quads() {
    this.doc.addPage();
    this.doc.setLineWidth(5.0);
    const x = 15;
    const y = 23;
    const width = 200;
    const height = 280;

    this.quad({
      quadPercentage: this.quadPercentage.stretchingMe,
      content: this.stretchingMe,
      x: x,
      y: y,
    });
    this.quad({
      quadPercentage: this.quadPercentage.connectingMe,
      content: this.connectingMe,
      x: 2 * x + width,
      y: y,
    });
    this.quad({
      quadPercentage: this.quadPercentage.meFirst,
      content: this.meFirst,
      x: x,
      y: 2 * y + height,
    });
    this.quad({
      quadPercentage: this.quadPercentage.protectingMe,
      content: this.protectingMe,
      x: 2 * x + width,
      y: 2 * y + height,
    });
  }

  quad(payload) {
    const width = 200;
    const height = 280;
    const radius = 5;
    const { content, quadPercentage } = payload;
    const quadValue = Math.round(
      (100 *
        content.reduce((accumulator, currentValue) => {
          return accumulator + Math.round(currentValue.value / 10);
        }, 0)) /
        this.totalScore
    );
    let y = payload.y;
    let x = payload.x;
    const newLine = 10;
    const emojiSize = this.emojiWidth;
    const drawColor = this.hexToDec(content[0].background);
    this.doc.setDrawColor(drawColor[0], drawColor[1], drawColor[2]);
    this.doc.setTextColor(drawColor[0], drawColor[1], drawColor[2]);
    this.doc.roundedRect(x, y, width, height, radius, radius, 'S');
    x += 20;
    y += 50;
    const indent = x + 50;
    this.doc.setFontSize(this.scoreFontSize);
    const quadText = `${content[0].quad}: ${quadPercentage}%`;
    const textWidth = this.doc.getTextWidth(quadText);
    this.doc.text(quadText, x - 20 + width / 2 - textWidth / 2, y - 25);
    y -=12;
    content.forEach((emoji) => {
      this.doc.setTextColor('00', '00', '00');
      this.doc.setFontSize(this.scoreFontSize);
      let yInc = 0;
      this.doc.addImage(
        this.imageServer[emoji.emotion].image.x40,
        // images.getImage(emoji.emotion),
        'JPEG',
        x,
        y,
        this.emojiWidth,
        this.emojiWidth*this.imageServer[emoji.emotion].image.ratio
      );
      this.doc.text(
        `${emoji.value !== undefined ? Math.round(emoji.value / 10) : '--'}`,
        indent + 90,
        y + emojiSize / 1.7
      );
      this.doc.setFontSize(this.bodyFontSize);
      yInc += newLine + 5;
      y = y + yInc;
      const splitText = this.doc.splitTextToSize(emoji.content, 80);
      let ret = this.doc.text(splitText, indent, y + this.getAlign(splitText.length));
      y = y + emojiSize;
      yInc += newLine;
      yInc += emojiSize / 1.59;
    });
  }

  getAlign(splitText){
    switch(splitText){
      case 2: return 5; 
      case 3: return 0;
      default: return -4;
    }
  }
  
  hexToDec(colorString) {
    const red = parseInt(colorString.substr(1, 2), 16);
    const green = parseInt(colorString.substr(3, 2), 16);
    const blue = parseInt(colorString.substr(5, 2), 16);
    return [red, green, blue];
  }
}
