export default class Instructions {
  loopViewer_element = document.getElementById('loopViewer');
  instuctions;
  isIOS
  constructor(payload) {
    const { attributes, loop } = payload;
    this.isIOS=attributes.isIOS
    loop.on('mouseout', () => {
      this.instructions.style.zIndex = '1';
    });
    loop.on('mouseover', () => {
      this.instructions.style.zIndex = '-1';
    });
    loop.on('ringReset', () => {
      this.instructions.style.zIndex = '1';
    });
    loop.on('click', () => {
      this.instructions.style.zIndex = '-1';
    });
    this.elements();
  }
  elements() {
    this.instructions = document.createElement('div');
    this.instructions.style.width = '100%';
    this.instructions.style.height = '100%';
    this.instructions.style.gridRow = 2;
    this.instructions.style.position = 'absolute';
    this.instructions.style.top = '0px';
    this.instructions.style.zIndex = '1';
    this.instructions.style.background = 'white';
    this.instructions.style.display = 'flex';
    this.instructions.style.flexDirection = 'column';
    this.instructions.style.justifyContent = 'space-evenly';
    this.instructions.style.fontSize = '14px';
    this.instructions.id='instructions';
    
    const padding = '0 10px';
    const par_1 = document.createElement('p');
    par_1.innerHTML =
      'The Emotions Ring Profile is designed to help you reflect on how you’re feeling.';
    par_1.style.padding = padding;
    const par_2 = document.createElement('p');
    par_2.style.padding = padding;
    par_2.innerHTML = `Click on an emoji then drag the dot in the middle to the rating you want to select. Work through each emoji. Read the description and use the scale to give a number (from 0 to 10) to show how much of the time you experience that group of emotions. ${this.isIOS ?  '': 'You can add some text to explain your rating.'}`
    const par_3 = document.createElement('p');
    par_3.style.padding = padding;
    par_3.innerHTML =
      'Save your work using the green save button. A pdf will be created. You can save this in the usual way for your device to work on at a later date (it can be reloaded using the yellow button).';
    this.instructions.appendChild(par_1);
    this.instructions.appendChild(par_2);
    this.instructions.appendChild(par_3);

    this.loopViewer_element.appendChild(this.instructions);
  }
}