import images from '../../../images/emojiring/*.png'
export default class BarMenu {
  attributes;
  menu = false;
  loopViewer = document.getElementById('loopViewer');
  constructor(payload) {
    this.elements();
  }
  elements() {
    const loopMenu = document.createElement('div');
    loopMenu.id = 'loopMenu';
    loopMenu.style.width = '100%';
    loopMenu.style.gridRow = 1;
    loopMenu.style.display = 'flex';
    loopMenu.style.justifyContent = 'space-around';
    const dashboardDisplay = document.createElement('img');
    dashboardDisplay.id = 'loopDashboardImage';
    // dashboardDisplay.src = 'images/emojiring/loop.png';
    dashboardDisplay.src = `${images['loop']}`;
    dashboardDisplay.alt = 'loop menu';
    loopMenu.appendChild(dashboardDisplay);
    this.loopViewer.appendChild(loopMenu);
  }
}
