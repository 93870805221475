import Login from '../auth/Login.js';
import Hamburger from './Hamburger.js';
import Masthead from './MastHead.js';

export default class MenuBar {
  menu = document.getElementById('menu');
  login;
  constructor(payload) {
    this.elements();
    new Hamburger(payload)
    new Masthead(payload)
    new Login(payload);
  }
  
  elements() {
    this.menu.style.background = 'black';
    this.menu.style.height = '80px';
    this.menu.style.margin = '0 0';
    this.menu.style.display = 'flex';
    this.menu.style.justifyContent = 'space-between';
    this.menu.style.alignItems = 'center';
  }
}
