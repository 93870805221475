import Authorised from "./Authorised.js";
export default class AuthorisedMobile extends Authorised{
    makeContainer(){
        const container = document.createElement('div');
        container.style.width = '80%';
        container.style.display = 'grid';
        container.style.gridTemplateRows = '1fr auto';
        container.style.rowGap = '1em';
        container.style.justifyContent = 'center';
        return container
      }
    
}