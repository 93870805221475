import BarMenu from '../Menus/BarMenu.js';
import Content from './Content.js';
import Instructions from './Instructions.js';
export default class BarController {

  loop = document.getElementById('loop')

  constructor(payload) {
    this.elements(payload)
    this.components(payload)
  }

  elements(payload){
    const loopViewer = document.createElement('div')
    loopViewer.id='loopViewer'
    const loopContent = document.createElement('div')
    loopContent.id='loopContent'
    const loopScoop = document.createElement('div')
    loopScoop.id='loopScoop'
    loopContent.appendChild(loopScoop)
    loopViewer.appendChild(loopContent)
    loop.appendChild(loopViewer)
  }

  components(payload){
    new BarMenu(payload);
    new Content(payload);
    new Instructions(payload);
  }
}
