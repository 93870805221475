export default class BufferLoader{
    context
    urlList
    onLoad
    bufferList=[]
    loadCount=0
    constructor(payload){
        this.context=payload.context
        this.urlList=payload.urlList
        this.onload=payload.onload
    }

    loadBuffer(url, index){
        var request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "arraybuffer";
        request.onload= (event)=>{ 
            this.context.decodeAudioData(
                request.response,
                (buffer)=>{
                    if(!buffer){
                        alert('error decoding file data: ',+url);
                    }
                    this.bufferList[index]=buffer
                    if(++this.loadCount==this.urlList.length){
                        this.onload(this.bufferList)
                    }
                },
                (error)=>{
                    console.log('error: ',error)
                }
            )
            request.onerror = (error) => {
                alert('BufferLoader: XHR error: ',error);
            }
        }
        request.send()
    }
}