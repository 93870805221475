export default class Modal{
  content=document.getElementById('content')
  menuModal
  constructor(payload){
    payload.menuModel.onMenuEvent('showMenu',(payload2)=>{
      if (payload2){
        this.menuModal.style.zIndex='100'
        this.menuModal.classList.remove('transitionOut')
        this.menuModal.classList.add('transitionIn')
      }else{
        this.menuModal.style.zIndex='-1'
        this.menuModal.classList.remove('transitionIn')
        this.menuModal.classList.add('transitionOut')

      }
    })
    this.elements(payload.menuAttributes)
  }
  makeMenuText(payload){
    return payload.match(/[A-Z][a-z]+/g).reduce((previousValue, currentValue)=>{
      return `${previousValue} ${currentValue}`
    },'')
  }
  elements(attributes){
    const menuModal = document.createElement('div')
    menuModal.id='menuModal'
    menuModal.classList.add('menuModal')
    const menuContainer=document.createElement('div')
    const items = Object.keys(attributes.items)
    const menu = document.createElement('ul')
    items.forEach((item)=>{
      const newItem = document.createElement('li')
      newItem.innerHTML=this.makeMenuText(item)
      if (typeof attributes.items[item] === 'object' && attributes.items[item]!==null){
        const subMenu = document.createElement('ul')
        const subItems = Object.keys(attributes.items[item])
        const isUrl = Object.keys(attributes.items[item]).filter((isUrl)=>isUrl==='url')[0]
        if(isUrl){
          const link=document.createElement('a')
          link.href=attributes.items[item][isUrl]
          
          menu.appendChild(link)
          newItem.classList.add('mainMenu')
          newItem.classList.add('menuUlActive')
          link.appendChild(newItem)
        }else{
          newItem.classList.add('mainMenu')
          newItem.classList.add('menuUl')
          menu.appendChild(newItem)
        }
        subItems.forEach((subItem)=>{
          if (subItem!=='url'){
            const subMenuItem = document.createElement('li')
            subMenuItem.classList.add('mainMenu')
            subMenuItem.classList.add('menuLi')
            subMenuItem.innerHTML=this.makeMenuText(subItem)
            const subItemLink = document.createElement('a')
            subItemLink.href=attributes.items[item][subItem]
            subItemLink.appendChild(subMenuItem)
            subMenu.appendChild(subItemLink)
          }
        })
        menu.appendChild(subMenu)
      }else{
        const  link = document.createElement('a')
        link.href=attributes.items[item]
        newItem.classList.add('mainMenu')
        newItem.classList.add('menuLi')
        link.appendChild(newItem)
        menu.appendChild(link)
      }
      menuContainer.appendChild(menu)
    })
    menuModal.appendChild(menuContainer)    
    this.content.appendChild(menuModal)
    this.menuModal=menuModal
  }

















  // if (typeof items === 'object' && items!==null){
  //   const subMenu = document.createElement('ul')
  //   const subItems = Object.keys(attributes.items[items])
  //   subItems.forEach((subItems)=>{
  //     const newSubItem = document.createElement('li')
  //   })

  // }else{

  // }
}