export default class Track {
  parent = document.getElementById('tracks');
  radius = 192;
  loop
  emotion
  count
  element
  constructor(payload) {

    const { loop, emotion,count, element } = payload;
    this.loop = loop;
    this.emotion=emotion
    this.count=count
    this.element=element

    this.loop.on('rung', (payload) => {
      if (payload.emotion === this.emotion){
        this.render(payload)
      }
    });
    
    this.loop.on('update', (payload)=>{
      
      this.render(payload[emotion]);
    })
  }
  get xyC(){
    return 300;
  }
  
  render(payload){
    const track = document.getElementById(`${this.emotion}_track`);
    if (track){
      this.parent.removeChild(track);
    }
    if (payload.value){
      const end = {
        x: this.xyC +this.radius *(payload.value/100)*Math.cos((2 * Math.PI * this.count ) / (this.loop.spine.length) + Math.PI / 16),
        y: this.xyC + this.radius *(payload.value/100)*Math.sin((2 * Math.PI * this.count) / (this.loop.spine.length) + Math.PI / 16),
      }          
      this.path = this.element.path(`M${this.xyC} ${this.xyC} L${end.x} ${end.y}`);
      this.path.attr({
        stroke: this.loop.content[this.emotion].colour,
        strokeWidth: 5,
        opacity: 1,
        strokeLinecap: 'round',
        id: `${this.emotion}_track`,
      });
    }      
  }
}
