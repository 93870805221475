import Login from '../../auth/Login.js';
import Mute from '../Sound/Mute.js'
// import Spreadsheet from './Spreadsheet.js';
export default class SiteMenu {
  menu = document.getElementById('menu');
  login;
  mute
  spreadsheet
  constructor(payload) {
    this.login = new Login(payload);
    this.mute = new Mute(payload)
    // this.spreadsheet = new Spreadsheet(payload)
    this.elements2();
  }
  elements() {
    const menu = document.createElement('menu');
    menu.style.background = 'black';
    menu.style.height = '80px';
    menu.style.margin = '0 0';
    menu.style.padding = '0 1rem';
    menu.style.display = 'flex';
    menu.style.justifyContent = 'space-between';
    menu.style.alignItems = 'center';


    const lhs = document.createElement('div');
    lhs.style.display = 'flex'
    lhs.style.justifyContent = 'left'
    lhs.style.alignItems='center'

    const lhs_image = document.createElement('img');
    lhs_image.src = 'images/emojiring/loop_white.jpg';
    lhs.appendChild(lhs_image);
    menu.appendChild(lhs);

    const rhs = document.createElement('rhs')
    rhs.style.display='flex'
    rhs.style.justifyContent = 'space-between';
    rhs.style.alignItems = 'center';
    rhs.appendChild(this.login)
    rhs.appendChild(this.mute)
    menu.appendChild(rhs);
    this.menu.appendChild(menu);
  }
  elements2() {
    // const menu = document.createElement('menu');
    this.menu.style.background = 'black';
    this.menu.style.height = '80px';
    this.menu.style.margin = '0 0';
    // this.menu.style.padding = '0 1rem';
    this.menu.style.display = 'flex';
    this.menu.style.justifyContent = 'space-between';
    this.menu.style.alignItems = 'center';

    const emojiring = document.createElement('div');
    const emojiring_image = document.createElement('img');
    emojiring_image.src = 'images/emojiring/loop_white.jpg';
    emojiring.appendChild(emojiring_image);
    
    const logout = document.createElement('rhs')
    this.menu.appendChild(this.mute)
    // this.menu.appendChild(this.spreadsheet)
    this.menu.appendChild(emojiring);
    this.menu.appendChild(this.login)
    // this.menu.appendChild(menu);
  }
}
