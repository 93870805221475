export default class Radar {
  steps = 16;
  linewidth = 1.0
  radius;
  textRadius;
  margin = 20;
  xValues = [];
  yValues = [];
  textXValues = [];
  textYValues = [];
  scoreXValues = [];
  scoreYValues = [];
  centerX;
  centerY;
  doc;
  spine;
  polygonPoints = [];
  constructor(payload) {
    const { x, y, width } = payload;
    this.doc = payload.doc;
    this.extendJSPDF();
    // this.doc.rect(x,y,width,width)
    this.radius = (width - 2 * this.margin) / 2;
    this.textRadius = this.radius + 20;
    this.centerX = x + width / 2;
    this.centerY = y + width / 2;
    this.spine = payload.spine;
    this.drawSpines();
  }

  drawSpines() {
    // let zero = false;
    for (var i = 0; i < this.spine.length; i++) {
    //   if(Math.round(this.spine[i][1].value/10)===0){
    //     zero = true
    //   }
      this.xValues[i] =
        this.centerX +
        this.radius *
          Math.cos((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.yValues[i] =
        this.centerY +
        this.radius *
          Math.sin((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.scoreXValues[i] =
        this.centerX +
        this.radius *
          (Math.round(this.spine[i][1].value / 10) / 10) *
          Math.cos((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.scoreYValues[i] =
        this.centerY +
        this.radius *
          (Math.round(this.spine[i][1].value / 10) / 10) *
          Math.sin((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.textXValues[i] =
        this.centerX +
        this.textRadius *
          Math.cos((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.textYValues[i] =
        this.centerY +
        this.textRadius *
          Math.sin((2 * Math.PI * i) / this.spine.length + Math.PI / 16);
      this.polygonPoints[i] = [this.scoreXValues[i], this.scoreYValues[i]];
    }

    this.polygonPoints.push(this.polygonPoints[0]);
    this.doc.setLineWidth(this.linewidth);
    // this.doc.setLineWidth(1.0);
    
    this.doc.setFillColor('3', '256', '62');
    // this.doc.polygon(this.polygonPoints, [1.0, 1.0], 'FD');
    for (var i = 0; i < this.spine.length; i++) {
      const lineColor = this.hexToDec(this.spine[i][1].colour);
      this.doc.setDrawColor(lineColor[0], lineColor[1], lineColor[2]);
      this.doc.circle(this.scoreXValues[i], this.scoreYValues[i], 2, 'FD');
    }
    this.doc.setLineWidth(this.linewidth);
    for (var i = 0; i < this.spine.length; i++) {
      const lineColor = this.hexToDec(this.spine[i][1].colour);
      this.doc.setDrawColor(lineColor[0], lineColor[1], lineColor[2]);
      this.doc.line(
        this.centerX,
        this.centerY,
        this.scoreXValues[i],
        this.scoreYValues[i]
      );
    }
    // if (zero){
    //   this.doc.setDrawColor(0,0,0);
    //   this.doc.setFillColor(0,0,0);
    //   this.doc.circle(this.centerX, this.centerY, 2, 'FD');
    // }
  }

  hexToDec(colorString) {
    const red = parseInt(colorString.substr(1, 2), 16);
    const green = parseInt(colorString.substr(3, 2), 16);
    const blue = parseInt(colorString.substr(5, 2), 16);
    return [red, green, blue];
  }

  extendJSPDF() {
    this.doc.polygon = function (points, scale, style, closed) {
      var x1 = points[0][0];
      var y1 = points[0][1];
      var cx = x1;
      var cy = y1;
      var acc = [];
      for (var i = 1; i < points.length; i++) {
        var point = points[i];
        var dx = point[0] - cx;
        var dy = point[1] - cy;
        acc.push([dx, dy]);
        cx += dx;
        cy += dy;
      }
      this.lines(acc, x1, y1, scale, style, closed);
    };
  }
}
