export default class ClusterEmotions {
  loop;
  loopScoop = document.getElementById('loopScoop');
  loopViewerCluster;
  loopViewerClusterParagraph;
  loopViewerClusterParagraphHeading;
  constructor(payload) {
    this.elements();
    const { loop } = payload;
    this.loop = loop;
    try {
      this.update(this.loop.content[this.loop.currentContent]);
    } catch (error) {
      sessionStorage.removeItem('emojiring')
      this.loop.criticalError=true
      this.loop.trigger('criticalError',error)
    }

    this.loop.on('mouseover', (emotion) => {
      this.update(this.loop.content[emotion]);
    });

    this.loop.on('click', (emotion) => {
      this.update(this.loop.content[emotion]);
    });
  }
  update(update) {
    this.loopViewerClusterParagraph.innerHTML = update.cluster
      .toString()
      .replaceAll(',', ', ');
    this.loopViewerClusterParagraphHeading.innerHTML = `Being ${update.emotion} can make us feel...`;
  }

  elements() {
    this.loopViewerCluster = document.createElement('div');
    this.loopViewerCluster.id = 'loopViewerCluster';
    this.loopViewerClusterParagraphHeading = document.createElement('h2');
    this.loopViewerClusterParagraphHeading.id =
      'loopViewerClusterParagraphHeading';
    this.loopViewerClusterParagraph = document.createElement('p');
    this.loopViewerClusterParagraph.id = 'loopViewerClusterParagraph';

    this.loopViewerCluster.appendChild(this.loopViewerClusterParagraphHeading);
    this.loopViewerCluster.appendChild(this.loopViewerClusterParagraph);
    this.loopScoop.appendChild(this.loopViewerCluster);
  }
}
