export default class Contact{
  ip

  name = document.getElementById('contact-name');
  email = document.getElementById('contact-email');
  desc = document.getElementById('contact-message');
  submit = document.getElementById('contact-submit');
  recapcha = document.getElementById('captcha');

  debounced=false
  
  constructor(payload){
    window.recaptcha_call=this.recaptcha_call2
    this.elements()
  }

  async recaptcha_call2(event) {
    try{
			const response = await fetch("https://api.ipify.org/?format=json");
			var data = await response.json();
      window.ip=data.ip
		}
		catch(err){
      console.log('callback error: ',err)
		}
  }

  submitToAPI2(payload){
    if (window.ip&&!this.debounced){
      this.debounced=true
      payload.preventDefault();

      let Namere = /[A-Za-z]{1}[A-Za-z]/;
      if (!Namere.test(this.name.value)) {
        this.debounced=false
        alert('Name cannot be less than 2 characters in length.');
        return;
      }

      if (!this.validateEmail()) {
        this.debounced=false
        alert('Please enter a valid email.');
        return;
      }

      if (this.desc.value == '') {
        this.debounced=false
        alert('Please enter a message.');
        return;
      }

      const data = {
        name: this.name.value,
        email: this.email.value,
        desc: this.desc.value,
        context: 'ET'
      };

      const headers = {'Content-Type': 'application/json; charset=utf-8'};

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(data),
      };

      const apiUrl = 'https://903omrggpi.execute-api.us-east-1.amazonaws.com/dev/captcha/contact';
      this.response(`Thanks for the inquiry. We will be back in touch soon.`)
      fetch(apiUrl, options)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          this.response(`Sorry there has been an error, try again later.
          Error: ${json.error}`)
        }
      })

    }else{
      if (!window.ip){
        alert('Sorry, we need proof you are not robotic.');
      }
    }
  }
  
  response(payload){
    const div = document.createElement('div')
    div.style.display='flex'
    div.style.minHeight='100%'
    div.style.flexDirection='column'
    div.style.justifyContent='space-evenly'
    const p = document.createElement('div')
    p.style.textAlign='center'
    p.innerHTML=payload
    div.appendChild(p)
    document.getElementById('landingContet').replaceChildren(div)
  
  }

  validateEmail() {
    var x = this.email.value;
    var atpos = x.indexOf('@');
    var dotpos = x.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
      alert('Not a valid e-mail address');
      return false;
    }
    return true;
  }


  elements(){
    this.submit.addEventListener('click',(e)=>{
      this.submitToAPI2(e) 
    })
  }
}