import RingMobile from "./RingMobile.js";
import EmojiButton from "./EmojiButton.js";
import Slider from "./Slider.js";

export default class SlidersMobile extends RingMobile {
  ring = document.getElementById("ring");
  sliders;
  xyC = 170;
  radius = 130;
  buttonOffset = 22;
  highLight = 17;
  ringPosition


  constructor(payload) {
    super(payload);
    this.sliderFactory();
  }

  sliderFactory() {
    const sliderSVGContainer = document.createElement("div");
    sliderSVGContainer.style.width = "100%";
    sliderSVGContainer.style.height = "100%";
    sliderSVGContainer.style.zIndex = "30";
    sliderSVGContainer.style.position = "absolute";
    sliderSVGContainer.style.top = "0";
    sliderSVGContainer.style.right = "0";
    sliderSVGContainer.id='sliderSVGContainer'


    const sliderSVG = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );


    sliderSVG.setAttribute("width", this.dim.l);
    sliderSVG.setAttribute("heigth", this.dim.l);
    sliderSVG.setAttribute("viewbox", this.dim.viewbox);
    sliderSVG.setAttribute("preserveAspectRatio", "none");
    sliderSVG.setAttribute("id", "sliderSVG");
    sliderSVG.style.zIndex = "29";
    sliderSVG.style.width = "100%";
    sliderSVG.style.height = "100%";
    // sliderSVG.style.touchAction = "pan-x pan-y";
    sliderSVGContainer.appendChild(sliderSVG);
    this.ring.appendChild(sliderSVGContainer);
    this.loop.ringPosition=sliderSVGContainer.getBoundingClientRect()
    this.ringPosition=sliderSVGContainer
    this.sliders = Snap("#sliderSVG");
    let count = 0;
    this.loop.spine.forEach((keyValue) => {
      const payload = {
        loop: this.loop,
        emotion: keyValue[0],
        element: this.sliders,
        xyC: this.xyC,
        radius: this.radius,
        end: {
          x:
            this.xyC +
            this.radius *
              Math.cos(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
          y:
            this.xyC +
            this.radius *
              Math.sin(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
        },
        button: {
          x:
            this.xyC +
            (this.radius + this.buttonOffset) *
              Math.cos(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
          y:
            this.xyC +
            (this.radius + this.buttonOffset) *
              Math.sin(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
        },
      };
      new EmojiButton(payload);
      new Slider(payload);
      const x =
        this.xyC +
        (this.radius + this.buttonOffset) *
          Math.cos(
            (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
          );
      const y =
        this.xyC +
        (this.radius + this.buttonOffset) *
          Math.sin(
            (2 * Math.PI * count++) / this.loop.spine.length + Math.PI / 16
          );
      this.emojiActive[payload.emotion] = this.snapElementMiddle
        .circle(x, y, this.highLight)
        .attr({
          fill: keyValue[1].background,
          strokeWidth: 4,
          stroke: "black",
          opacity: 0,
        });
    });
  }
}
