export default class Analytics{
  content=document.getElementById('content')
  menuModel

  constructor(payload){
    this.menuModel=payload.menuModel
    this.elements()
  }

  elements(){
    const consent = document.createElement('div')
    const info = document.createElement('div')
    const buttons = document.createElement('div')
    consent.id='consent'
    info.id='constentInfo'
    buttons.id='consentButtons'
    const yes=document.createElement('div')
    const no=document.createElement('div')
    yes.innerHTML='Yes'
    no.innerHTML='No'
    this.buttonElements(yes)
    this.buttonElements(no)
    buttons.appendChild(yes)
    buttons.appendChild(no)
    info.style.display='flex'
    info.style.alignItems='center'
    info.style.height='100%'
    info.style.marginLeft='1em'
    info.innerHTML='Can emotions-tracker.com collect anonymous data on your visit?'
    
    yes.addEventListener('click',(e)=>{
      e.preventDefault();
      consent.style.display='none'
      this.menuModel.consent=true
    })

    no.addEventListener('click',(e)=>{
      e.preventDefault();
      consent.style.display='none'
      this.menuModel.consent=false
    })

    buttons.style.display='flex'
    buttons.style.flexDirection='column'
    buttons.style.justifyContent='space-between'

    consent.appendChild(info)
    consent.appendChild(buttons)
    this.content.appendChild(consent)
  }
  buttonElements(element){
    element.style.background='white'
    element.style.width='120px'
    element.style.height='25px'
    element.style.fontSize='20px'
    element.style.borderRadius='1em'
    element.style.border='1px solid #bdc3c7'
    element.style.cursor='pointer'
    element.style.marginRight='1em'
    element.style.marginTop='0.2em'
    element.style.marginBottom='0.2em'
    element.style.background='black'
    element.style.color='white'
    
    element.style.textAlign='center'
    element.style.paddingTop='0.1em'
  }
}