export default class MenuAttributes {
  attributes={
    items: {
      // emotionsTracker: '../../emotions-tracker/index.html',
      
      Home: '../../index.html',
      EmotionsRingProfile: 'emojiring.html',
      // emojiSocial: '../../emojiSocial/index.html',
      // shop: '../../shop/index.html',
      TheThinkingBehindTheRing: 'theory.html',
      Contact: 'contact.html',
      Credits: 'credits.html',
      YourData: 'sitePolicy.html',
      // utilities: {
      //   spreadsheet: '../../spreadsheet/index.html',
      //   timeStamps: '../../time',
      //   url: '../../utilities/index.html'
      // },
      // dummy: {
      //   spreadsheet: '../../spreadsheet/index.html',
      //   timeStamps: '../../time',
      // }
    },
  }
  constructor(event) {}

  get items(){
    return this.attributes.items
  }

  set items (payload){
    this.attributes.items[Object.keys(payload)[0]]=payload[Object.keys(payload)[0]]
  }
}
    // In-line menu attribute to add item code on post authorisation from resource server. 
    // attributes.items={dynamicElementTest: '../icicles/index.html'}

