export default class Rung {
  rung;
  value;
  loop;
  constructor(payload) {
    const { attributes } = payload;
    if (attributes.settings.loopType === 'sliders') {
      this.elements();
      this.loop = payload.loop;
      this.loop.on('rung', (payload) => {
        // console.log('payload value: ',payload.value)
        // if (payload.value) {
          // console.log('init')
          // if (decimal !== this.value) {
            // this.value = decimal;
            // this.rung.innerHTML = Math.round(payload.value / 10);
            this.renderRung(Math.round(payload.value / 10))
        // }
      });
      this.loop.on('update', () => {
        this.rung.innerHTML = ``;
      });
      this.loop.on('mouseover', (emotion) => {
        this.makeView(emotion);
      });
      this.loop.on('mouseout', (emotion) => {
        this.rung.innerHTML = ``;
        // this.makeView();
      });

      this.loop.on('click', (emotion) => {
        this.makeView(emotion);
      });
    }
  }

  renderRung(payload){
    if (payload !== this.value) {
        this.value = payload;
        this.rung.innerHTML=payload
    }
  }

  get loopScoop(){
    return document.getElementById('loopScoop')
  }
  get rungContainerID(){
    return 'rungContainer'
  }
  get rungID(){
    return 'rung'
  }
  makeView(emotion) {
    let content = this.loop.content[emotion];
    if (content.value !== undefined) {
      this.rung.innerHTML = Math.round(content.value / 10);
    } else {
      this.rung.innerHTML = ``;
      this.value=undefined
    }
  }

  elements() {
    const rungContainer = document.createElement('div');
    rungContainer.id = this.rungContainerID;
    rungContainer.style.order = '2';
    rungContainer.style.display='flex'
    rungContainer.style.minWidth='100%'
    rungContainer.style.alignItems='center'
    rungContainer.style.justifyContent='center'

    const rung = document.createElement('h1');
    rung.id=this.rungID
    rung.style.fontSize = '50px';
    rung.style.margin = '0.3em 0';
    rung.style.display = 'flex';
    rung.style.justifyContent = 'center';
    rungContainer.appendChild(rung)
    this.loopScoop.appendChild(rungContainer);
    this.rung = rung;
  }
}
