export default class DialogOK{
  dialog=document.getElementById('dialog')
  _action
  _info
  dialogContent
  _p
  buttonContainer
  constructor(payload){
    const {menuModel, menuAttributes}=payload
    this.elements(menuModel)
  }

  elements(model){
    console.log('elements: ',model)
    const dialogContent = document.createElement('div')
    dialogContent.id='dialogContent'
    dialogContent.style.display='flex'
    dialogContent.style.flexDirection='column'
    dialogContent.style.alignContent='center'
    dialogContent.style.minWidth='100%'
    const info=document.createElement('p')
    info.id='dialogOKText'
    this._info = info
    
    // const button=document.createElement('button')
    // button.id='dialogOK'
    // button.innerHTML='OK'
    
    // this.mainButtonAction({
    //   button: button,
    //   model: model
    // })
    // // button.addEventListener('click', () => {
    // //   this.dialog.style.zIndex = '-1';
    // //   this.dialog.style.opacity = '0';
    // //   console.log('action: ',this._action)
    // //   model.triggerMenuEvent(this._action)
    // // });
    // const buttonContainer = document.createElement('div')
    // buttonContainer.style.display='flex'
    // buttonContainer.style.width='100%'
    // buttonContainer.style.justifyContent='center'

    // buttonContainer.appendChild(button)
    dialogContent.appendChild(info)
    this.makeThirdElement({parent: dialogContent, model: model})
    // dialogContent.appendChild(buttonContainer)
    this.dialogContent=dialogContent
  }
  
  makeThirdElement(payload){
    const {parent, model}= payload
    const button=document.createElement('button')
    button.id='dialogOK'
    button.innerHTML='OK'
    
    this.mainButtonAction({
      button: button,
      model: model
    })
    // button.addEventListener('click', () => {
      //   this.dialog.style.zIndex = '-1';
      //   this.dialog.style.opacity = '0';
      //   console.log('action: ',this._action)
      //   model.triggerMenuEvent(this._action)
      // });
    const buttonContainer = document.createElement('div')
    buttonContainer.style.display='flex'
    buttonContainer.style.width='100%'
    buttonContainer.style.justifyContent='center'
    
    buttonContainer.appendChild(button)
    
    
    parent.appendChild(buttonContainer)
    
    this.buttonContainer=buttonContainer

  }

  mainButtonAction(payload){
    const {button, model}=payload
    button.addEventListener('click', () => {
      this.dialog.style.zIndex = '-1';
      this.dialog.style.opacity = '0';
      model.triggerMenuEvent(this._action)
    });

  }


  set content(payload){
    console.log('content payload: ',payload)
    const {action, info} = payload
    this._action = action
    this._info.innerHTML=info
  }

  get content(){
    return this.dialogContent
  }

  styleButton(element){
    element.style.background='white'
    element.style.width='120px'
    element.style.height='50px'
    element.style.fontSize='20px'
    element.style.borderRadius='1em'
    element.style.border='1px solid #bdc3c7'
    element.style.cursor='pointer'
  }

}