import RungMobile from "./RungMobile.js";
import EmojiContent from "./EmojiContent.js";
export default class EmojiContentMobile extends EmojiContent{
    constructor(payload){

        super(payload)
    }

    get flexDirection(){
        return 'row'
    }

    rung(payload){
        new RungMobile(payload)
    }
    get emojiContentID(){
        return 'emojiContent'
    }
    get gridColumns(){
        return 'auto 7fr 2.1fr'
    }

    elements() {
        const emojiContent = document.createElement('div');
        emojiContent.id=this.emojiContentID

        emojiContent.style.display='grid'
        emojiContent.style.gridTemplateColumns=this.gridColumns
        emojiContent.style.columnGap='5px'
        emojiContent.style.order='1'


        emojiContent.style.order = 1;
        emojiContent.style.margin = '0 0.5em';
        emojiContent.style.fontSize = '14'
    
        const emojiImageContainer=document.createElement('div')
        emojiImageContainer.style.display='flex'
        emojiImageContainer.style.alignItems='center'
        const emojiImage = document.createElement('img');
        emojiImage.id=this.emojiImageID
        emojiImage.src = 'images/emojiring/blank.png';
        emojiImage.style.width = '78px';
        emojiImage.style.height = '90px';
        emojiImage.alt = 'emoji'
        emojiImageContainer.appendChild(emojiImage)
    
        const emojiParagraph = document.createElement('p');
        emojiParagraph.style.textAlign = 'center';
        emojiParagraph.id=this.emojiParagraph
    
        emojiContent.appendChild(emojiImageContainer);
        emojiContent.appendChild(emojiParagraph);
        this.loopScoop.appendChild(emojiContent);
        
        this.emojiImage=emojiImage
        this.emojiParagraph=emojiParagraph
      }
    
    
}