import EmojiContentMobile from "./EmojiContentMobile.js";
import RungMobileStoryBox from "./RungMobileStoryBox.js";
export default class EmojiContentMobileStoryBox extends EmojiContentMobile{
    constructor(payload){

        super(payload)
    }
    get loopScoop(){
        return document.getElementById('loopDialogContent');
    }

    get emojiContentID(){
        return 'emojiContentStoryBox'
    }
    get gridColumns(){
        return '2fr 7fr 1fr'
    }



    get emojiImageID(){
        return 'emojiImageStoryBoxMobile'
    }

    get emojiParagraphID(){
        return 'emojiParagraphStoryBoxMobile'
    }

    rung(payload){
        new RungMobileStoryBox(payload)
      }
    
}