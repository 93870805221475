import Ring from './Ring.js';
import Slider from './Slider.js';
import EmojiButton from './EmojiButton.js';
export default class Sliders extends Ring {
  ring = document.getElementById('ring');
  sliders;
  xyC = 300;
  radius = 192;
  buttonOffset = 55;

  constructor(payload) {
    super(payload);
    this.sliderFactory();
    this.loop.on('garbage',()=>{
      console.log('garbage')
    })
    // this.elementsSlider();
  }

  makeElements(){
  }

  sliderFactory() {
    const sliderSVGContainer = document.createElement('div');
    sliderSVGContainer.style.width = '100%';
    sliderSVGContainer.style.height = '100%';
    sliderSVGContainer.style.zIndex = '30';
    sliderSVGContainer.style.position = 'absolute';
    sliderSVGContainer.style.top = '0';
    sliderSVGContainer.style.right = '0';

    const sliderSVG = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );

    sliderSVG.setAttribute('width', this.dim.l);
    sliderSVG.setAttribute('heigth', this.dim.l);
    sliderSVG.setAttribute('viewbox', this.dim.viewbox);
    sliderSVG.setAttribute('preserveAspectRatio', 'none');
    sliderSVG.setAttribute('id', 'sliderSVG');
    sliderSVG.style.zIndex = '29';
    sliderSVG.style.width = '100%';
    sliderSVG.style.height = '100%';
    sliderSVGContainer.appendChild(sliderSVG);
    this.ring.appendChild(sliderSVGContainer);
    this.sliders = Snap('#sliderSVG');
    let count = 0;
    this.loop.spine.forEach((keyValue) => {
      const payload = {
        loop: this.loop,
        emotion: keyValue[0],
        element: this.sliders,
        xyC: this.xyC,
        radius: this.radius,
        end: {
          x:
            this.xyC +
            this.radius *
              Math.cos(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
          y:
            this.xyC +
            this.radius *
              Math.sin(
                (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
              ),
        },
        button: {
          x:
              this.xyC +
              (this.radius+this.buttonOffset) *
                Math.cos(
                  (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
                ),
          y:
              this.xyC +
              (this.radius+this.buttonOffset) *
                Math.sin(
                  (2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16
                ),
        }
      };
      new EmojiButton(payload);
      new Slider(payload);
      const x = this.xyC + (this.radius+this.buttonOffset) * Math.cos((2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16)
      const y = this.xyC +(this.radius+this.buttonOffset) * Math.sin((2 * Math.PI * count++) / this.loop.spine.length + Math.PI / 16)
      this.emojiActive[payload.emotion] = this.snapElementMiddle
      .circle(x, y, this.highLight)
      .attr({
        fill: keyValue[1].background,
        strokeWidth: 4,
        stroke: 'black',
        opacity: 0,
      });

  
    });
  }

  elementsSlider() {
    // console.log('sliders elements')
    // const content = this.loop.spine;
    // console.log(this.loop.spine)
    // let count = 0
    // const x = this.xyC + (this.radius+this.buttonOffset) * Math.cos((2 * Math.PI * count) / this.loop.spine.length + Math.PI / 16)
    // const y = this.xyC +(this.radius+this.buttonOffset) * Math.sin((2 * Math.PI * count++) / this.loop.spine.length + Math.PI / 16)
    // for (let emoji in content) {
    //   console.log('loop: ',emoji)
    //   // console.log('x: ',x,'     y: ',y)
    //   this.emojiActive[emoji[0]] = this.snapElementMiddle
    //     .circle(x, y, this.highLight)
    //     .attr({
    //       fill: content[emoji[0]].background,
    //       strokeWidth: 4,
    //       stroke: 'black',
    //       opacity: 0,
    //     });
    // }
  }

}
