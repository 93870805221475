import LoadingGradient from "./LoadingGradient.js"
import DialogOK from "./DialogOK.js"

export default class DialogResourceServer extends DialogOK{
    constructor(payload){
        super(payload)
    }
    makeThirdElement(payload){
    const {parent}= payload
        parent.appendChild(new LoadingGradient())
    }
}