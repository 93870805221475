export default class Slider {
  loop;
  attributes;
  slider; //= document.getElementById('slider');
  sliderValue; //= document.getElementById('sliderValue');
  //   sliderValueContainer = document.getElementById('sliderValueContainer');

  constructor(payload) {
    const { attributes } = payload;
    if (attributes.settings.loopType === 'slider') {
      this.makeSlider();
      this.loop = payload.loop;
      this.attributes = payload.attributes;
      this.loop.on('click', (emotion) => {
        this.makeView(emotion);
      });

      this.loop.on('lock', (isLocked) => {
        this.slider.disabled = !isLocked;
        this.slider.style.opacity = this.sliderValue.style.opacity = isLocked
          ? '1'
          : '0.2';
      });

      this.loop.on('mouseover', (emotion) => {
        this.makeView(emotion);
      });

      this.init();
      this.setView(this.attributes.settings);
    }
  }
  makeSlider() {
    const sliderContainer = document.createElement('div');
    sliderContainer.id = 'sliderContainer';
    sliderContainer.style.display = 'flex';
    sliderContainer.style.order = 2;
    const sliderValue = document.createElement('h2');
    sliderValue.id = 'sliderValue';
    sliderContainer.appendChild(sliderValue);
    const sliderForm = document.createElement('form');
    sliderForm.name = 'sliderForm';
    sliderForm.id = 'sliderForm';
    sliderContainer.appendChild(sliderForm);
    const slider = document.createElement('input');
    slider.name = 'sliderElement';
    slider.id = 'slider';
    slider.type = 'range';
    slider.min = '0';
    slider.max = '100';
    slider.value = '-1';
    slider.class = 'slider';
    sliderForm.appendChild(slider);
    this.slider = slider;
    this.sliderValue = sliderValue;
    document.getElementById('loopScoop').appendChild(sliderContainer);
  }
  init() {
    console.log('value: ',document.sliderForm.sliderElement.value); 
    this.slider.disabled = true;
    document.sliderForm.sliderElement.addEventListener('change', () => {
      this.sliderValue.innerHTML = `${Math.round(
        document.sliderForm.sliderElement.value / 10
      )}`;
      let content = this.loop.content[this.loop.selectedContent];
      content.value = Number(document.sliderForm.sliderElement.value);
      this.loop.content = content;
      document.getElementById('slider').style.opacity = '1';
    });
  }

  setView(settings) {
    document.getElementById('sliderForm').style.display =
      settings.loopType === 'slider' ? 'flex' : 'none';
  }

  makeView(emotion) {
    let content = this.loop.content[emotion];
    if (content.value) {
      document.sliderForm.sliderElement.value = content.value;
      this.sliderValue.innerHTML = `${Math.round(content.value / 10)}`;
    } else {
      this.sliderValue.innerHTML = ``;
      document.sliderForm.sliderElement.value = '0';
    }
  }
}
