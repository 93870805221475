import Content from "./Content.js";
import Slider from './Components/Slider.js';
import Rung from './Components/Rung.js';
import ClusterEmotions from './Components/ClusterEmotions.js';
import EmojiContentMobile from './Components/EmojiContentMobile.js';
import StoryBoxMobileDisplay from './Components/StoryBoxMobileDisplay.js';

export default class ContentMobile extends Content{
    constructor(payload){

        super(payload)
    }

    components(payload){
        new ClusterEmotions(payload);
        new EmojiContentMobile(payload);
        // new Rung(payload);
        // new Slider(payload);
        new StoryBoxMobileDisplay(payload);
      }
    

    elements(payload){
        // const emoji=document.getElementById('emojiImage')
        // const description=document.getElementById('emojiParagraph')
        // // const rung = document.getElementById('rungContainer')
        // // const rungH1 = document.getElementById('rung')
        // // rungH1.style.margin='0'

        // const topLine = document.createElement('div')
        // topLine.style.display='grid'
        // topLine.style.gridTemplateColumns='2fr 7fr 2.1fr'
        // topLine.style.columnGap='5px'
        // topLine.style.order='1'
        // topLine.appendChild(emoji)
        // topLine.appendChild(description)
        // // topLine.appendChild(rung)

        // document.getElementById('loopScoop').appendChild(topLine)
    }
}