import DialogOK from "./DialogOK.js"
export default class DialogProcedeCancel extends DialogOK{
  constructor(payload){
    super(payload)
    this.additionalElements(payload.menuModel)
  }
  
  additionalElements(model){
    this.buttonContainer.style.justifyContent='space-around'
    const button2=document.createElement('button')
    button2.id='dialogCancel'
    button2.innerHTML='Cancel'

    button2.addEventListener('click', () => {
      console.log('off add')
      this.dialog.style.zIndex = '-1';
      this.dialog.style.opacity = '0';
      model.triggerMenuEvent('dismis')

    });
    this.styleButton(button2)
    this.buttonContainer.appendChild(button2)
  }
  mainButtonAction(payload){
    const {button, model}=payload
    button.addEventListener('click', () => {
      this.dialog.style.zIndex = '-1';
      this.dialog.style.opacity = '0';
      model[this._action]()
    });

  }

}