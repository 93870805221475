export default class Dialog {
  constructor(payload) {
    const {loop} = payload;
    loop.on('dialog', (content) => {
      const popup = {
        type: 'popup',
        content: `You need to complete the emoji-ring to save. You have ${content} still to do.`
      }
      loop.trigger('popup',popup)
    });
    loop.on('saveFail', () => {
      const popup = {
        type: 'saveFail',
        content: `Unable to read this file.`
      }
      loop.trigger('popup',popup)
    });
    loop.on('logout',()=>{
      const popup = {
        type: 'logout',
        content: `If you continue to log out you will lose the emojiring you are currently working on (if you have not saved).`
      }
      loop.trigger('popup',popup)
    })
  }

}
