export default class Footer{
    content=document.getElementById('content')
    constructor(payload){
        this.elements()
        // payload.loop.on('media',(media)=>{
            // document.getElementById('footer').style.height='2.5rem'
        // })
    }
    elements(){
        const footer=document.createElement('footer')
        footer.id='footer'
        const copyright = document.createElement('div')
        copyright.innerHTML='© 2023 whatmotivateslearning.com'
        copyright.style.textAlign='center'
        footer.style.zIndex='300'
        footer.appendChild(copyright)
        this.content.appendChild(footer)
        // document.body.appendChild(footer)
        // this.container.appendChild(footer)

    }
}