import images from '../../../images/emojiring/*.jpg'
export default class Mute{
    muteOn
    muteOff
    container
    baseElement=document.getElementById('baseElements')
    constructor(payload){
        this.elements()
        const attributes = payload.attributes;
        if (attributes.mute){

        }
        this.muteImage = attributes.mute
        this.container.addEventListener('click',(event)=>{
            attributes.mute=!attributes.mute
            this.muteImage = attributes.mute
            // this.container.innerHTML=attributes.mute?this.mute:this.unmute
        })
        payload.loop.on('login', (logged) => {
            this.container.style.display=payload.loop.authorized?'flex':'none'
        })
        return this.container
    }
    set muteImage(payload){
        this.muteOn.style.display='none'
        this.muteOff.style.display='none'
        if(payload){
            this.muteOn.style.display='flex'
        }else{
            this.muteOff.style.display='flex'
        }
    }
    elements(){
        const muteOff = document.createElement('img')
        muteOff.alt='Mute off'
        muteOff.src=`${images['mute_on']}`
        // muteOff.src='../images/emojiring/mute_on.jpg'
        const muteOn = document.createElement('img')
        muteOn.alt='Mute Off'
        // muteOn.src='../images/emojiring/mute_off.jpg'
        muteOn.src=`${images['mute_off']}`
        muteOn.style.display='none'
        const container = document.createElement('div')
        // container.style.display='none'
        container.style.alignItems='center'
        container.style.alignContent='center'
        container.style.margin='0.5rem 0.5rem'
        container.style.fontSize='2rem'
        container.style.cursor='pointer'
        container.appendChild(muteOn)
        container.appendChild(muteOff)
        // container.style.=''
        // this.baseElement.appendChild(container)
        this.container=container
        this.muteOn=muteOn
        this.muteOff=muteOff
    }
}