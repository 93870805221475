import Emotion from './Emotion.js';
// import PKCE from '../auth/PKCE.js';
export default class Loop {
  i_currentContent = 'friendly';
  i_selectedContent;
  contentLock = false;
  modalLock = false;
  authorised = false;
  _menuController
  
  _media
  viewportPoint = 794
  _ringPosition
  _touch=false
  _emotionsTracker
  _menuController

  constructor(payload) {
    const {events,menuController}=payload
    this._menuController=menuController
    this.events = events;
    this.on = this.events.on;
    this.trigger = this.events.trigger;
    this._emotionsTracker = JSON.parse(sessionStorage.getItem('emojiring'))
    this.emotion = new Emotion();
    window.addEventListener('resize', (e)=>{
      this.trigger('media',this.media)
    });
  }

  loadState(){
    if (this._emotionsTracker){
      const nameText = document.getElementById('nameInput');
      try {
        if (this._emotionsTracker.emotion.friendly.actorName){
            nameText.value = this._emotionsTracker.emotion.friendly.actorName;          
        }
        this.update(this._emotionsTracker.emotion);
      } catch (error) {
        console.log('Critical error from loop.loadState')
      }
    }
  }

  storeState(){
    const state=JSON.stringify(this.emotion)
    sessionStorage.setItem('emojiring',state)
  }

  garbage(){
    const blank = new Emotion()
    this.trigger('garbage')
    // this.update(blank)
  }

  set touch(payload){
    this._mobile=payload
  }

  get touch(){
    return this._touch
  }

  get currentContent() {
    if (this.contentLock) {
      return this.i_selectedContent;
    } else {
      return this.i_currentContent;
    }
  }

  pollPositions(){
    if (this.ringPosition=document.getElementById('sliderSVGContainer')){
      this.ringPosition=document.getElementById('sliderSVGContainer').getBoundingClientRect()      
    }
  }

  get ringPosition(){
    return this._ringPosition
  }

  set ringPosition(payload){
    this._ringPosition=payload
  }

  set currentContent(payload) {
    if (!this.contentLock) {
      const key = Object.keys(payload)[0];
      const value = payload[key];
      this.trigger(key, value);
      this.i_currentContent = value;
    } else {
    }
  }

  get selectedContent() {
    return this.i_selectedContent;
  }

  set selectedContent(payload) {
    const key = Object.keys(payload)[0];
    const value = payload[key];
    if (this.i_selectedContent === value) {
      // document.getElementById('body').style.touchAction='none'
      this.trigger('ringReset');
      this.lock = false;
      this.trigger('updateStory', this.i_selectedContent);
      this.i_selectedContent = undefined;
    } else if (this.i_selectedContent != value) {
      if (!this.i_selectedContent) {
        this.trigger('updateStory', value);
      } else {
        this.trigger('updateStory', this.i_selectedContent);
      }
      this.lock = true;
      this.trigger('click', value);
      // document.getElementById('body').style.touchAction='auto'
      this.i_selectedContent = value;
    }
  }

  get content() {
    return this.emotion.expression;
  }

  set content(payload) {
    this.emotion.expression = payload;
    this.trigger('rung', payload);
  }

  get lock() {
    return this.contentLock;
  }

  set lock(isLocked) {
    this.contentLock = isLocked;
    this.trigger('lock', this.contentLock);
  }

  update(payload) {
    this.emotion.update(payload);
    this.contentLock = false;
    this.i_selectedContent = undefined;
    this.trigger('update', payload);
  }

  report() {
    // this.trigger('publish');
    let unfinished = [];
    let content = '';
    for (const emotion in this.emotion.expression) {
      if (this.emotion.expression[emotion].value === undefined) {
        unfinished.push(emotion);
      }
    }
    if (unfinished.length > 0) {
      if (unfinished.length > 1) {
        const lastElement = unfinished.pop();
        content = unfinished.toString().replaceAll(',', ', ');
        content += ` and ${lastElement}`;
      } else {
        content = unfinished[0];
      }
      this.trigger('dialog', content);
      this.modal = true;
    } else {
      this.trigger('publish');
    }
  }

  get quadrant() {
    return {
      stretchingMe: this.getQuad('Stretching Me'),
      connectingMe: this.getQuad('Connecting Me'),
      protectingMe: this.getQuad('Protecting Me'),
      meFirst: this.getQuad('Me First'),
    };
  }

  get quadrantTotalScore() {
    return [
      this.quadrant.stretchingMe,
      this.quadrant.connectingMe,
      this.quadrant.protectingMe,
      this.quadrant.meFirst,
    ]
      .flatMap((num) => num)
      .reduce((accumulator, currentValue) => {
        return accumulator + Math.round(currentValue.value / 10);
      }, 0);
  }

  get quadPercentage() {
    let po = {};
    const quads = this.quadrant;
    const poHelper = (quad) => {
      return (
        (100 *
          quads[quad].reduce((accumulator, currentValue) => {
            return accumulator + Math.round(currentValue.value / 10);
          }, 0)) /
        this.quadrantTotalScore
      );
    };
    for (const quad in quads) {
      po[quad] = Math.round(poHelper(quad));
    }
    return po;
  }

  get quadrantScore() {
    return {
      stretchingMe: this.getQuadScore('Stretching Me'),
      connectingMe: this.getQuadScore('Connecting Me'),
      protectingMe: this.getQuadScore('Protecting Me'),
      meFirst: this.getQuadScore('Me First'),
    };
  }

  getQuad(name) {
    return Object.values(this.content)
      .filter((em) => em.quad === name)
      .sort((a, b) => {
        return b.value - a.value;
      });
  }

  getQuadScore(quad) {
    return this.getQuad(quad).reduce(
      (accumulator, currentValue) =>
        accumulator + Math.round(currentValue.value / 10),
      0
    );
  }

  fileError() {
    this.modal = true;
    this.trigger('saveFail', this);
  }

  get spine() {
    return Object.entries(this.content).sort(
      (a, b) => a[1].spider - b[1].spider
    );
  }

  set modal(payload) {
    this.modalLock = payload;
    this.trigger('modalLock', this.modalLock);
  }

  get modal() {
    return this.modalLock;
  }

  authorisationOnBlocks() {
    this.authorized = false;
    this.trigger('emotionRingProfile')
  }
  authorisationOffBlocks(){
    this.authorized = true;
    
  }

  
  // logout(){
  //   localStorage.setItem('refresh','void')
  //   localStorage.setItem('loggedIn','false')
  //   window.location.href ='https://auth.whatmotivateslearning.com/logout?client_id=p2lbiq2ceaulj7ofrlqjqn6k7&logout_uri=https://emotions-tracker.com/index.html';
  // }

  set authorized(payload) {
    this.authorised = payload;
    this.trigger('login', this.authorised);
  }

  get authorized(){
    return this.authorised
  }

  get media(){
    return {
      available: document.body.clientWidth,
      portrait: window.matchMedia("(orientation: portrait)").matches
    }

  }    

  set media(payload){
      this.trigger('media',this.media)
      this._media=payload
  }

  get resetForRotation(){
    const width = this.viewportPoint > document.body.clientWidth
    const height = this.viewportPoint > document.body.clientHeight
    return (width && !height) || (!width && height)
  }
  
  set criticalError(payload){
    this._menuController.criticalError=payload
  }

  get criticalError(){
    return this._menuController.criticalError=payload
  }
}
