import SlidersMobile from "./SlidersMobile.js";
import TracksMobile from "./TracksMobile.js"
import Tracks from "./Tracks.js";
import RingMobile from "./RingMobile.js";
import RingController from "./RingController.js";
export default class RingControllerMobile extends RingController{
constructor(payload){
    super(payload)
}

loopType(payload){
  switch (payload.attributes.dashboard.loopType) {
    case 'sliders':
      new SlidersMobile(payload);
      new TracksMobile(payload);
      break;
    default:
      new RingMobile(payload);
      break;
  }
  
}

get dim(){
    return {
      l: '340',
      viewbox: '0 0 340 340' 
    };
  }
}