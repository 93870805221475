import JSONFilter from './JSONFilter.js';
import FrontPage from './FrontPage.js';
import QuadPage from './QuadPage.js';
import Key from './Key.js';
import jsPDF from 'jspdf';
// import jsPDF from '../../../jsLIB/jspdf.es.js';
// import jsPDF from '../../../jsLIB/jspdf.min.1.3.2.js';
// import jsPDF from "../../../jsLIB/jspdf.debug.new.js"
export default class ReportingController {
  loop;
  attributes;
  doc;
  json;
  metadata;
  imageServer;
  jsonFilter;
  nameText = document.getElementById('nameInput');
  constructor(payload) {
    this.loop = payload.loop;
    this.attributes = payload.attributes;
    this.init();
    this.loop.on('publish', () => {
      this.execute();
    });
  }

  init() {
    document.getElementById('p1').addEventListener('click',(e)=>{
      console.log('test')
    // })
    // $('#p1').on('click', () => {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        const fileInput = document.getElementById('fileInput');
        fileInput.intermediate = true;
        fileInput.addEventListener(
          'change',
          () => {
            var reader = new FileReader();
            reader.onloadend = (evt) => {
              if (evt.target.readyState === FileReader.DONE) {
                const theSet = evt.target.result;
                const jsonFilter = new JSONFilter();
                const end = theSet.search('</jspdf:metadata>');
                const begin = theSet.search('<jspdf:metadata>') + 16;
                const metadata = theSet.slice(begin, end);
                const json = jsonFilter.filterJSONIn(metadata);
                try {
                  const loadedLoop = JSON.parse(json);
                  console.log('loadedLoop: ', loadedLoop);
                  this.nameText.value = loadedLoop.friendly.actorName;
                  this.loop.update(loadedLoop);
                } catch (error) {
                  this.loop.fileError();
                  return;
                }
              }
            };
            const file = document.getElementById('fileInput').files[0];
            reader.readAsText(file);
          },
          false
        );
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        const edge = ua.indexOf('Edge/');
        const trident = ua.indexOf('Trident/');
        if (msie > 0 || edge > 0 || trident > 0) {
          $(function () {
            $(document).on('click', '#fileInput', function () {
              if (this.indeterminate) {
                $(this).trigger('change');
              }
            });
          });
        }
        document.getElementById('fileInput').click();
      } else {
        this.loop.trigger('saveFail');
      }
    });
    document.getElementById('except').addEventListener('click',()=>{
      this.loop.report();
    })
    // $('#except').on('click', () => {
    //   this.loop.report();
    // });
  }

  execute() {
    const uid = new Key()
    const jsonFilter = new JSONFilter();
    let data = this.loop.content;
    data.friendly.actorName = this.nameText.value || '';
    data.friendly.key = uid.key
    data.friendly.timeStamp=new Date().toString()
    const json = JSON.stringify(data);
    this.metadata = jsonFilter.filterJSONOut(json);
    this.doc = new jsPDF('p', 'px', 'a4');
    this.doc.addMetadata(this.metadata);
    const s = this.loop.spine;
    const payload = {
      doc: this.doc,
      actorName: this.nameText.value,
      imageServer: this.imageServer,
      loop: this.loop,
    };

    new FrontPage(payload);
    new QuadPage(payload);

    this.doc.save(
      `${jsonFilter.filterFileName(data.friendly.actorName)} emoji ring.pdf`
    );
  }
}
