export default class LoadingGradient{
    constructor(){
        return this.elements();
    }
    elements(){
        const loaderContainer = document.createElement('div')
        loaderContainer.className='loader-container'
        const loader = document.createElement('div')
        loader.className='loader'
        const innerCircle = document.createElement('div')
        innerCircle.className='inner-circle'
        loader.appendChild(innerCircle)
        loaderContainer.appendChild(loader)
        return loaderContainer
    }
}