import images from '../../../../images/emojiring/*.png'
import Rung from "./Rung.js";

export default class EmojiContent {
  loop;
  emojiContent;
  emojiImage;
  emojiParagraph;
  constructor(payload) {
    const { loop } = payload;
    this.elements();
    this.rung(payload)
    this.loop = loop;
    try {
      this.update(this.loop.content[this.loop.currentContent]);
    } catch (error) {
      this.loop.criticalError=true
      // console.log('emojicontent update error: ',error)
    }
    
    this.loop.on('mouseover', (emotion) => {
      this.update(this.loop.content[emotion]);
    });
    
    this.loop.on('click', (emotion) => {
      this.update(this.loop.content[emotion]);
    });
  }
  rung(payload){
    new Rung(payload)
  }

  get flexDirection(){
    return 'column'
  }

  get loopScoop(){
    return document.getElementById('loopScoop');
  }

  get emojiImageID(){
    return 'emojiImage'
  }

  get emojiParagraphID(){
    return 'emojiParagraph'
  }

  elements() {
    const emojiContent = document.createElement('div');
    emojiContent.id='emojiContent'
    emojiContent.style.display = 'flex';
    emojiContent.style.flexDirection = this.flexDirection;
    emojiContent.style.order = 1;
    emojiContent.style.margin = '0 0.5em';
    emojiContent.style.alignItems = 'center';
    emojiContent.style.alignContent = 'center';
    emojiContent.style.justifyContent = 'center';
    emojiContent.style.fontSize = '14'

    const emojiImage = document.createElement('img');
    emojiImage.id=this.emojiImageID
    emojiImage.src = `${images['blank']}`
    // emojiImage.src = 'images/emojiring/blank.png';
    emojiImage.style.width = '78px';
    emojiImage.style.height = '90px';
    emojiImage.style.maxWidth='100%';
    emojiImage.alt = 'emoji'

    const emojiParagraph = document.createElement('p');
    emojiParagraph.style.textAlign = 'center';
    emojiParagraph.id=this.emojiParagraph

    emojiContent.appendChild(emojiImage);
    emojiContent.appendChild(emojiParagraph);
    this.loopScoop.appendChild(emojiContent);
    
    this.emojiImage=emojiImage
    this.emojiParagraph=emojiParagraph
  }
  update(update) {
    `${images['loop_white']}`
    this.emojiImage.src = `${images[update.emotion]}`;
    // this.emojiImage.src = `./images/emojiring/${update.emotion}.png`;
    this.emojiParagraph.innerHTML = update.content;
  }
}
