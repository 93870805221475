export default class Emotion {
  emotion = {
    determined: {
      content: 'I know what I want to achieve and keep going. I will succeed.',
      x: 54.5,
      y: 251.5,
      emotion: 'determined',
      background: '#7839ff',
      clusterSetting: 'Determination helpers:',
      cluster: [
        'Enthusiasm',
        'Excitement',
        'Determination',
        'Passion',
        'Ambition',
      ],
      quad: 'Stretching Me',
      spider: 9,
      colour: '#7f5935',
      angle: 0,
    },
    adventurous: {
      content:
        "I'm up for trying new things and taking risks. I feel things will go well.",
      x: 91.6,
      y: 164.3,
      emotion: 'adventurous',
      background: '#7839ff',
      clusterSetting: 'Adventurous emotions...',
      cluster: [
        'Wonder',
        'Curiosity',
        'Admiration',
        'Hope',
        'Optimism',
        'Boldness',
        'Bravery',
      ],
      quad: 'Stretching Me',
      spider: 10,
      colour: '#eb2617',
      angle: 0,
    },
    proud: {
      content: "I'm doing well. I know I can get even better.",
      x: 161.5,
      y: 97,
      emotion: 'proud',
      background: '#7839ff',
      clusterSetting: 'Different ways to be proud:',
      cluster: ['Confidence', 'Pride'],
      quad: 'Stretching Me',
      spider: 11,
      colour: '#ffbd0a',
    },
    thrilled: {
      content:
        "I'm enjoying what I'm doing, and things are going really well for me. I feel like I matter.",
      x: 251,
      y: 61,
      emotion: 'thrilled',
      background: '#7839ff',
      clusterSetting: 'Thrilling ways:',
      cluster: ['Awe', 'Joy', 'Valued'],
      quad: 'Stretching Me',
      spider: 12,
      colour: '#03ff66',
      angle: 0,
    },
    friendly: {
      content:
        'I feel comfortable and safe. I feel good about those around me and am having fun.',
      x: 348,
      y: 61,
      emotion: 'friendly',
      background: '#20ff65',
      clusterSetting: 'Being a friend feels like:',
      cluster: [
        'Belonging',
        'Trust',
        'Camaraderie',
        'Playfulness',
      ],
      quad: 'Connecting Me',
      spider: 13,
      colour: '#1252eb',
      angle: 0,
    },
    thankful: {
      content:
        'I feel grateful to others for what I have. I make the most of things.',
      x: 439,
      y: 98,
      emotion: 'thankful',
      background: '#20ff65',
      clusterSetting: 'Different ways to feel thankful:',
      cluster: [
        'Contentment',
        'Calmness',
        'Gratitude',
      ],
      quad: 'Connecting Me',
      spider: 14,
      colour: '#ff1fff',
      angle: 0,
    },
    kind: {
      content:
        'I want to share what I have. I feel good about doing the right thing.',
      x: 507,
      y: 165.7,
      emotion: 'kind',
      background: '#20ff65',
      clusterSetting: 'Some kind things:',
      cluster: [
        'Kindness',
        'Pleased for others',
        'Responsibility',
      ],
      quad: 'Connecting Me',
      spider: 15,
      colour: '#eb7317',
      angle: 0,
    },
    caring: {
      content: "I feel for others and I'm there for them, whatever they need.",
      x: 544,
      y: 251,
      emotion: 'caring',
      background: '#20ff65',
      clusterSetting: 'Some ways to feel caring:',
      cluster: [
        'Sympathy',
        'Empathy',
        'Compassion',
        'Forgiveness',
      ],
      quad: 'Connecting Me',
      spider: 16,
      colour: '#00ffff',
      angle: 0,
    },
    down: {
      content:
        "I'm missing people, feeling low and a bit lost. I need reassured.",
      x: 543,
      y: 345.5,
      emotion: 'down',
      background: '#00ffff',
      clusterSetting: 'Different ways to feel down:',
      cluster: ['Loneliness', 'Disappointment', 'Sadness', 'Regret'],
      quad: 'Protecting Me',
      spider: 1,
      colour: '#7f5935',
      angle: 0,
    },
    scared: {
      content:
        "I'm worried; I can't focus. Everything is too much. I'm not up for anything.",
      x: 506,
      y: 433,
      emotion: 'scared',
      background: '#00ffff',
      clusterSetting: 'Feeling scared...',
      cluster: [
        'Self-doubt',
        'Anxiety',
        'Fear',
        'Pessimism',
        'Overwhelmed',
        'Despair',
      ],
      quad: 'Protecting Me',
      spider: 2,
      colour: '#eb2617',
      angle: 0,
    },
    sorry: {
      content:
        "I've let people down and I feel like a bad person. I need to try and make up for it.",
      x: 437,
      y: 500,
      emotion: 'sorry',
      background: '#00ffff',
      clusterSetting: 'Feeling sorry:',
      cluster: [
        'Embarrassment',
        'Shyness',
        'Guilt',
        'Shame',
      ],
      quad: 'Protecting Me',
      spider: 3,
      colour: '#ffbd0a',
      angle: 0,
    },
    useless: {
      content:
        "I feel I'm not good enough and I don't matter. I keep to myself.",
      x: 347,
      y: 536,
      emotion: 'useless',
      background: '#00ffff',
      clusterSetting: 'What useless feels like:',
      cluster: ['Humiliation', 'Ignored', 'Inferiority'],
      quad: 'Protecting Me',
      spider: 4,
      colour: '#0eff66',
      angle: 0,
    },
    grudging: {
      content: 'I want to hurt people I hate. Nobody will mess with me.',
      x: 251,
      y: 536,
      emotion: 'grudging',
      background: '#000000',
      clusterSetting: 'Feeling grudging:',
      cluster: [
        'Resentment',
        'Contempt',
        'Disgust',
        'Spite',
        'Betrayal',
        'Hatred',
      ],
      quad: 'Me First',
      spider: 5,
      colour: '#1252eb',
      angle: 0,
    },
    ungrateful: {
      content: 'I expect special treatment and I can do whatever I want.',
      x: 160.5,
      y: 500,
      emotion: 'ungrateful',
      background: '#000000',
      clusterSetting: 'Feelings around ungrateful:',
      cluster: ['Arrogance', 'Over-entitlement', 'Ingratitude'],
      quad: 'Me First',
      spider: 6,
      colour: '#ff1fff',
      angle: 0,
    },
    mean: {
      content:
        "I'm annoyed other people have what I want. I'm happy when bad things happen to them.",
      x: 92,
      y: 434,
      emotion: 'mean',
      background: '#000000',
      clusterSetting: 'What mean means:',
      cluster: ['Schadenfreude', 'Gloating', 'Envy', 'Greed'],
      quad: 'Me First',
      spider: 7,
      colour: '#eb7317',
      angle: 0,
    },
    annoyed: {
      content: "I'm angry things aren't going my way. It's not fair.",
      x: 54.8,
      y: 347,
      emotion: 'annoyed',
      background: '#000000',
      clusterSetting: 'Annoyingly, I can`t ignore it:',
      cluster: [
        'Boredom',
        'Frustration',
        'Angry',
        'Offended',
        'Jealousy',
        'Revenge',
      ],
      quad: 'Me First',
      spider: 8,
      colour: '#00ffff',
      angle: 180,
    },
  };
emotion={}
  update(payload) {
    this.emotion = payload;
  }

  get emotion2(){
    return this._emotion
  }

  set emotion2(payload){
    this._emotion=payload
  }

  get expression() {
    return this.emotion;
  }
  
  set expression(payload) {
    this.emotion[payload.emotion] = payload;
  }
}
