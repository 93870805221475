import PKCE from '../auth/PKCE.js'
export default class MenuModel{

  worker = new Worker('../auth/worker.js');
  menuEvents
  onMenuEvent
  triggerMenuEvent
  _authorised=false
  _showMenu=false
  _path
  _isApp=false



  constructor(menuEvents){
    this.menuEvents=menuEvents;
    this.onMenuEvent=this.menuEvents.onMenuEvents;
    this.triggerMenuEvent= this.menuEvents.triggerMenuEvent;
    const url = new URL(window.location.href);
    this.path =url.pathname.substring(1,url.pathname.lastIndexOf("."));
    this._isApp=this.path=='emojiring'
        
    const pixy = {
      code: new URLSearchParams(url.search).get('code'),
      verifier: sessionStorage.getItem('verifier'),
      refresh_token: localStorage.getItem('refresh_token'),
    }
    console.log('loggedIn: ',pixy)

    if (pixy.verifier) {
      this.verify(pixy)
    } else if (pixy.refresh_token){
      if(this._isApp && !sessionStorage.getItem('emojiring')){
        this.refresh(pixy)
      }else{
        this.loggedIn()
      }
    } 
  }

  landing(){
    sessionStorage.removeItem('emojiring')
    localStorage.removeItem('refresh_token')
    this.authorised=false;
  }
      
  loggedIn(){
    this.authorised=true;    
  }
      
  loginError(payload){
    // this.authorised=false;
    this.triggerMenuEvent('authError',
      {type: 'popup',
        content: `There has been an authentication error. Select OK try to login again.`})
  }
          
  set authorised(payload){

    payload?this.triggerMenuEvent('loggedIn'):this.triggerMenuEvent('loggedOut')
    this._authorised=payload;
  }
          
  get authorised(){
    return this._authorised
  }
          
  refresh(payload1){
    const {refresh_token}=payload1
    this.worker.addEventListener('message',(payload3)=>{
      if(payload3.data.isAuthorised) {
        
        this.loggedIn()
        this.triggerMenuEvent('appUp',payload3.data.emotion)
        this.loop._menuController.menuModel.triggerMenuEvent('dialogDown')
        // this.triggerMenuEvent('appUp')
      }else{
        this.loop._menuController.menuModel.triggerMenuEvent('dialogDown')
        this.popup({type: 'popup',content:`<h1>Not Authorised</h1><p>Sorry, you're not authorised to use this resource.</p><p>Please use the contact page to arrange access.</p>`})
        this.loggedIn()
        // this.loginError()
      }
    }, {once:true})
    const payload2={
      refresh_token: refresh_token,
      flow: 'refresh',
    }
    this.triggerMenuEvent('dialogUp',{content: 'Getting an empty ring...', type: 'resourceServer'})
    this.triggerMenuEvent('contentServer')
    this.worker.postMessage(payload2);
  }
          
  verify(payload1){
    const {code, verifier}=payload1
    
    this.worker.addEventListener(
      'message',
      (payload3)=>{
        if(payload3.data.isAuthorised) {
          sessionStorage.removeItem('verifier')
          localStorage.setItem('refresh_token',payload3.data.refresh_token)
          this.loggedIn()
        }else{
          this.loginError()
          return
        }
      },
      { once: true }
    )
    const payload2={
      code: code,
      verifier: verifier,
      flow: 'verify',
    }
    this.worker.postMessage(payload2);
  }
          
  logOut(){
    const popup = {
      type: 'logout',
      content: `<h1>Logging Out</h1><p>If you continue to log out you will lose any work you have on the emotions-ring (if you have not saved).</p>`
    }
    this.popup(popup)
  }
          
  logOutConfirmed(){
    this.landing()
    this.worker.addEventListener(
      'message',
      (payload1)=>{        
        window.location.href = payload1.data.url
      },
      { once: true }
    )
    
    const payload2={
      flow: 'logout',
    }
    
    this.worker.postMessage(payload2);
    
  }
  
  criticalError(){
  }
          
  authErrorLogOut(){
    this.landing()
    window.location.href = direct
    
  }
          
  logIn(){
    localStorage.removeItem('refresh_token')
    this.authorise()
  }
          
  authorise(){
    const pkce = new PKCE();
    pkce.codes().then((codes)=>{
      sessionStorage.setItem('verifier', codes.verifier);
      this.worker.addEventListener(
        'message',
        (payload1)=>{        
          window.location.href = payload1.data.url
        },
        { once: true }
      )
      const payload2={
        flow: 'authorise',
        challenge: codes.challenge, 
      }
      this.worker.postMessage(payload2);
    })
  }
          
  showMenu(){
    this._showMenu=!this._showMenu
    this.triggerMenuEvent('showMenu',this._showMenu)
  }
          
  popup(payload){
    this.triggerMenuEvent('dialogUp', payload)
  }
          
  set path(payload){
    this._path=payload
  }
          
  get path(){
    return this._path
  }
          
  get redirect(){
    let uri='/'
    if (this.path!='/'){
      uri+=this.path+'/'
    }
    uri='index'
    return `${this.direct}${uri}.html`
  }
          
  set consent(payload){
    localStorage.setItem('analyticsConsent',payload)
  }
          
  get consent(){
      return localStorage('analyticsConsent')
  }
}