import Images from './Images.js';
import ImageServer from './ImageServer/ImageServer.js';

import Radar from './Radar.js';
import QuadScore from './QuadScore.js';
export default class FrontPage {
  doc;
  content;
  actorName;
  initX = 30;
  bodyFontSize = 10;
  scoreFontSize = 20;
  stretchingMe;
  connectingMe;
  protectingMe;
  meFirst;
  spine;
  imageServer = new ImageServer();
  constructor(payload) {
    this.content = payload.loop.content;
    this.doc = payload.doc;
    this.actorName = payload.actorName;
    this.stretchingMe = payload.loop.quadrantScore.stretchingMe;
    this.connectingMe = payload.loop.quadrantScore.connectingMe;
    this.protectingMe = payload.loop.quadrantScore.protectingMe;
    this.meFirst = payload.loop.quadrantScore.meFirst;
    this.spine = payload.loop.spine;
    this.makeDoc();
    new QuadScore(payload);
  }

  makeDoc() {
    const bodyWidth = 400;
    const images = new Images();
    this.doc.addImage(
      this.imageServer.tracker.image.x40,
      // images.getImage('ring'),
      'JPEG',
      20,
      200,
      bodyWidth,
      bodyWidth
    );
    this.doc.addImage(images.getImage('title2'), 'JPEG', 325, 30, 100,41);
    this.doc.addImage(images.getImage('copyright'), 'JPEG', 405, 180, 15, 15);
    this.doc.setFontSize(this.bodyFontSize);
    this.doc.text(this.date, 20, 50);
    this.doc.text(this.actorName, 20, 60);
    const positive = this.connectingMe + this.stretchingMe;
    const negative = this.protectingMe + this.meFirst;
    // const reducedRatio = this.reduce(Math.round(positive/4),Math.round(negative/4))
    const positivity = Math.round((100 * positive) / (positive + negative));
    const scoreX = 170;
    this.doc.setFontSize(this.scoreFontSize - 2);
    this.doc.text(`Positivity Score of ${positivity}%`, 20, scoreX);
    // const reducedRatio = this.reduce(Math.round(positive/4),Math.round(negative/4))
    // this.doc.text(`Positivity Ratio ${reducedRatio[0]} : ${reducedRatio[1]}`, 20, scoreX+20)
    this.doc.text(
      `Positivity Ratio ${
        Math.round((positive / 4 / (negative / 4)) * 10) / 10
      } : ${Math.round((negative / 4 / (negative / 4)) * 10) / 10}`,
      20,
      scoreX + 20
    );
    this.doc.setFontSize(this.bodyFontSize);
    new Radar({
      doc: this.doc,
      x: 70,
      y: 250,
      width: 300,
      spine: this.spine,
    });
  }

  get date() {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const today = new Date();
    return today.toLocaleDateString('en-US', options);
  }
  reduce(numerator, denominator) {
    var gcd = function gcd(a, b) {
      return b ? gcd(b, a % b) : a;
    };
    gcd = gcd(numerator, denominator);
    return [numerator / gcd, denominator / gcd];
  }
}
