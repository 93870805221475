import TrackMobile from "./TrackMobile.js";
import Tracks from "./Tracks.js";
export default class TracksMobile extends Tracks{
    constructor(payload){
        super(payload)
    }

    get xyC(){
        return 170
      }
      get radius(){
        return 96
      }

  get dim(){
    return {
      l: '340',
      viewbox: '0 0 340 340'
    };
  }

  makeTracks(payload){
    new TrackMobile(payload);
  }

}