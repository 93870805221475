import BufferLoader from "./BufferLoader.js"
import sound00 from '../../../sound/emojiring/00/*.mp3'
import sound01 from '../../../sound/emojiring/01/*.mp3'
export default class SoundController{
    loop
    attributes
    naration=[]
    play=false
    sound = new Audio()
    constructor(payload){
        this.loop = payload.loop
        this.attributes = payload.attributes
        const context = new AudioContext()
        let source = context.createBufferSource();
        this.random();

        const bufferLoader = new BufferLoader({
          context: context,
          urlList: this.naration.flatMap((key) =>{
            return key[1]
          }),
          onload: (bufferList)=>{}
        })

        for(let i=0; i<bufferLoader.urlList.length; i++){
          bufferLoader.loadBuffer(bufferLoader.urlList[i],i)
        }

        this.loop.on('updateStory', (payload)=>{
          // if (this.attributes.mute && source.buffer){
          if (source.buffer){
            source.stop()
          }
        })
        this.loop.on('mute',()=>{
          if (this.attributes.mute && source.buffer){
              source.stop()
        }})
        this.loop.on('click', (emotion) => {
          if (!this.attributes.mute){
              
            
            source = context.createBufferSource();
            source.buffer = bufferLoader.bufferList[
              this.loop.emotion.emotion[emotion].spider-1
            ];
            source.connect(context.destination);
            source.start()
            this.play=true
            source.onended=(payload)=>{
              this.play=false
            }
          }
        });  
  }

  random(){
    this.naration = this.loop.spine.reduce((accumulator, currentValue)=>{
      accumulator[currentValue[1].spider-1]=[
        currentValue[0],
        Math.random()>0.5 ? `${sound00[currentValue[0]]}`:`${sound01[currentValue[0]]}`
        // `../sound/emojiring/0${Math.random()>0.5 ? '0':'1'}/${currentValue[0]}.mp3`
      ]
      return accumulator
    },[])
  }
 
}
