export default class Eventing {
  events = {};
  constructor() {}
  on(eventName, callback) {
    const handlers = this.events[eventName] || [];
    handlers.push(callback);
    this.events[eventName] = handlers;
  }

  trigger(eventName, payload) {
    const handlers = this.events[eventName];
    if (!handlers || handlers.length === 0) {
      return;
    }
    handlers.forEach((callback) => {
      if (!payload) {
        callback();
      } else {
        callback(payload);
      }
    });
  }
}
