import mobileImages from '../../../images/emojiring/mobile/*.png'
import Ring from "./Ring.js";
export default class RingMobile extends Ring{
    constructor(payload){
        super(payload)
    }

    get imageSource (){
        return `${mobileImages['tracker']}`
    }
    
    get dim(){
        return {
          l: '340',
          viewbox: '0 0 340 340' 
        };
      }
        
    set emojiButtons(payload){
        this._emojiButtons = new EmojiButtons()
        this._emojiButtons.ring0(30)
    }
}