export default class PKCE {
  dec2hex(dec) {
    const ret = ('0' + dec.toString(16)).substr(-2);
    return ret;
  }

  generateCodeVerifier() {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join('');
  }

  sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  base64urlencode(a) {
    var str = '';
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  async generateCodeChallengeFromVerifier(v) {
    var hashed = await this.sha256(v);
    var base64encoded = this.base64urlencode(hashed);
    return base64encoded;
  }

  async getCodeChallenge() {
    try {
      this.codeChallenge = await this.generateCodeChallengeFromVerifier(
        this.codeVerifier
      );
      // this.codeChallenge;
    } catch (e) {
      this.codeChallenge = JSON.stringify(e);
    }
  }
  async codes() {
    const v = this.generateCodeVerifier();
    try {
      const c = await this.generateCodeChallengeFromVerifier(v);
      return {
        verifier: v,
        challenge: c,
      };
    } catch (e) {
      return JSON.stringify(e);
    }
  }
}
