export default class Login {
  loop;
  constructor(payload) {
    this.loop = payload.loop;
    // const muteButton = new Mute(payload)
    // console.log('muteButton: ',muteButton)
    const rhs = this.element();
    this.loop.on('login', (payload) => {
        rhs.innerHTML = 'logout';
      });
      return rhs;
    }
    
    element() {
      const rhs = document.createElement('div');
      rhs.id = 'logInOutAnchor';
      rhs.style.textDecoration = 'none';
      rhs.style.color = 'white';
      rhs.style.cursor = 'pointer';
      rhs.style.margin='0.5rem'
      rhs.innerHTML = '';
    rhs.addEventListener('click', () => {
      this.loop.trigger('logout')
      // this.loop.logout();
    });
    return rhs;
  }
}
