import Slider from './Components/Slider.js';
import Rung from './Components/Rung.js';
import ClusterEmotions from './Components/ClusterEmotions.js';
import EmojiContent from './Components/EmojiContent.js';
import StoryBox from './Components/StoryBox.js';
export default class Content {

  loopContent_element = document.getElementById('loopContent');

  constructor(payload) {
    const { loop } = payload;
    loop.on('mouseout', () => {
      this.loopContent_element.style.zIndex = '-1';
    });
    loop.on('mouseover', () => {
      this.loopContent_element.style.zIndex = '1';
    });
    loop.on('ringReset', () => {
      this.loopContent_element.style.zIndex = '-1';
    });
    loop.on('click', () => {
      this.loopContent_element.style.zIndex = '1';
    });
    this.components(payload)
    this.elements()
  }

  components(payload){
    new ClusterEmotions(payload);
    new EmojiContent(payload);
    new StoryBox(payload);
    // new Rung(payload);
    // new Slider(payload);
  }
  elements(){
  
  }
}
