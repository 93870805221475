export default class QuadScore{

    doc

    stretchingMe
    connectingMe
    protectingMe
    meFirst
    totalScore
    quadPercentage

    scoreFontSize=18;

    constructor(payload){
        this.doc = payload.doc
        this.quadPercentage= payload.loop.quadPercentage
        this.totalScore = payload.loop.quadrantTotalScore
        this.stretchingMe = payload.loop.quadrant.stretchingMe
        this.connectingMe = payload.loop.quadrant.connectingMe
        this.protectingMe = payload.loop.quadrant.protectingMe
        this.meFirst = payload.loop.quadrant.meFirst
        this.quads()

    }
    quads(){
        this.doc.setLineWidth(5.0); 
        const x=175
        const y=75
        const width=50
        const height=50

        this.quad({quadPercentage: this.quadPercentage.stretchingMe,content: this.stretchingMe,x: x,y: y})
        this.quad({quadPercentage: this.quadPercentage.connectingMe,content: this.connectingMe,x: x+width,y: y})
        this.quad({quadPercentage: this.quadPercentage.meFirst,content: this.meFirst,x: x,y: y+height})
        this.quad({quadPercentage: this.quadPercentage.protectingMe,content: this.protectingMe,x: x+width,y: y+height})
    }
      
    quad(payload){
        const width=40
        const height=40
        const radius = 5
        const {content, quadPercentage} = payload
        let y = payload.y
        let x = payload.x
        const drawColor= this.hexToDec(content[0].background)
        this.doc.setDrawColor(drawColor[0],drawColor[1],drawColor[2])
        this.doc.setTextColor(drawColor[0],drawColor[1],drawColor[2])
        this.doc.roundedRect(x, y, width, height, radius, radius, 'S')
        x+=10
        y+=10
        this.doc.setFontSize(this.scoreFontSize)
        const quadText = `${quadPercentage}%`
        const textWidth = this.doc.getTextWidth(quadText)
        this.doc.text(quadText,x-10+width/2-textWidth/2,y+13)
    }

    hexToDec(colorString) {
        const red=parseInt(colorString.substr(1,2),16)
        const green=parseInt(colorString.substr(3,2),16)
        const blue=parseInt(colorString.substr(5,2),16)
        return [red,green,blue];
    }


}