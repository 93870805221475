export default class Slider {
  rangeCircle;
  path;
  l = 0;
  searchDl = 1;
  pt;
  totalLen;
  group;
  bBox;
  loop;
  xyC;
  radius;
  constructor(payload) {
    const { loop, emotion, end, element, xyC, radius } = payload;
    this.xyC = xyC;
    this.radius = radius;
    this.loop = loop;
    const pathString = `M${String(xyC)} ${String(xyC)} L${end.x} ${end.y}`;
    this.path = element.path(pathString);
    this.pt = this.path.getPointAtLength(this.l);
    this.totalLen = this.path.getTotalLength();

    this.rangeCircle = element.circle(this.xyC, this.xyC, 12);
    this.rangeCircle.data("ox", this.rangeCircle.getBBox().cx);
    this.rangeCircle.data("oy", this.rangeCircle.getBBox().cy);
    this.group = element.g(this.path, this.rangeCircle);
    this.bBox = this.group.getBBox();
    this.group.transform(`t -1000, -1000`);
    this.rangeCircle.attr({
      stroke: this.loop.content[emotion].colour,
      fill: this.loop.content[emotion].colour,
      opacity: 1,
    });

    this.makeDrag();
    payload.loop.on("mouseover", (selection) => {
      if (emotion === selection) {
        transform(true);
      }
    });

    payload.loop.on("mouseout", (selection) => {
      if (emotion === selection) {
        transform(false);
      }
    });
    payload.loop.on("ringReset", () => {
      transform(false);
    });
    payload.loop.on("click", (selection) => {
      this.loop.touch=false
      transform(false);
      if (emotion === selection) {
        transform(true);
      }
    });
    payload.loop.on("update", (payload) => {
      // transform(true);
      const update = payload[emotion].value?payload[emotion].value:0;
      this.pt = this.path.getPointAtLength(0);
      const { x, y } = this.path.getPointAtLength(
        this.totalLen * (update / 100)
      );
      this.rangeCircle.transform(`t ${-(this.pt.x - x)}, ${-(this.pt.y - y)}`);
      transform(false);
    });

    const transform = (isVis) => {
      this.group.transform(isVis ? `t 0, 0` : `t -1000, -1000`);
    };
  }

  makeDrag() {
    this.rangeCircle.drag(
      (dx, dy, x, y, e) => {
        e.preventDefault();
        this.move(dx, dy);
      },
      (x, y, e) => {
        e.preventDefault();
        this.start();
      },
      (x, y) => {
        this.stop();
      }
    );

    this.rangeCircle.touchstart((x) => {
      this.loop.touch=true;
      if (typeof x == "object" && x.type == "touchstart") {
        x.preventDefault();
        this.rangeCircle.data("sx", x.changedTouches[0].clientX-this.loop.ringPosition.left);
        this.rangeCircle.data("sy", x.changedTouches[0].clientY-this.loop.ringPosition.top);
      }
    });

    this.rangeCircle.touchmove((dx, dy) => {
      if (typeof dx == "object" && dx.type == "touchmove") {
        const clientX = dx.changedTouches[0].clientX-this.loop.ringPosition.left;
        const clientY = dx.changedTouches[0].clientY-this.loop.ringPosition.top;
        dx = clientX - this.rangeCircle.data("sx");
        dy = clientY - this.rangeCircle.data("sy");
        this.move_helper(dx, dy);
      }
    });
    
    this.rangeCircle.touchend((dx, dy) => {
      // console.log("touchend: ", dx);
      if (typeof dx == "object" && dx.type == "touchend") {
        const clientX = dx.changedTouches[0].clientX-this.loop.ringPosition.left;
        const clientY = dx.changedTouches[0].clientY-this.loop.ringPosition.top;
        dx = clientX - this.rangeCircle.data("sx");
        dy = clientY - this.rangeCircle.data("sy");
        this.stop_helper(dx, dy);
      }
    });
  }

  move(dx, dy) {
    if(!this.loop.touch){
      this.move_helper(dx,dy)
    }
  }

  move_helper(dx,dy){
    this.getPoint({ dx: dx, dy: dy });
    if (!isNaN(this.pt.x) && !isNaN(this.pt.y)) {
      this.rangeCircle.transform(`t ${this.pt.x - this.rangeCircle.data("ox")}
      , ${this.pt.y - this.rangeCircle.data("oy")}`);
    }
    
  }

  start(x, y, e) {
    if (!this.loop.touch){
      this.start_helper(x,y,e)
    }
  }

  start_helper(x,y,e){
    this.rangeCircle.data("sx", this.rangeCircle.getBBox().cx);
    this.rangeCircle.data("sy", this.rangeCircle.getBBox().cy);

  }

  stop(dx, dy) {
    if (!this.loop.touch){
      this.loop.storeState()
      this.stop_helper(dx,dy)
    }
  }
  stop_helper(dx,dy){
    this.getPoint({ dx: dx, dy: dy });
  }

  getPoint(payload) {
    const { dx, dy } = payload;
    let tmpPt = {
      x: this.rangeCircle.data("sx") + dx,
      y: this.rangeCircle.data("sy") + dy,
    };
    this.l = this.gradSearch(this.l, tmpPt);
    this.pt = this.path.getPointAtLength(this.l);
    let content = this.loop.content[this.loop.selectedContent];
    content.value = this.rung;
    this.loop.content = content;
  }
  get rung() {
    return Math.round((this.l / this.totalLen + 0.001) * 100);
  }
  gradSearch(l0, pt) {
    l0 = l0 + this.totalLen;
    let l1 = l0,
      dist0 = this.dist(this.path.getPointAtLength(l0 % this.totalLen), pt),
      dist1,
      searchDir;
    if (
      this.dist(
        this.path.getPointAtLength((l0 - this.searchDl) % this.totalLen),
        pt
      ) >
      this.dist(
        this.path.getPointAtLength((l0 + this.searchDl) % this.totalLen),
        pt
      )
    ) {
      searchDir = this.searchDl;
    } else {
      searchDir = -this.searchDl;
    }
    l1 += searchDir;
    dist1 = this.dist(this.path.getPointAtLength(l1 % this.totalLen), pt);
    while (dist1 < dist0) {
      dist0 = dist1;
      l1 += searchDir;
      dist1 = this.dist(this.path.getPointAtLength(l1 % this.totalLen), pt);
    }
    l1 -= searchDir;
    return l1 % this.totalLen;
  }

  dist(pt1, pt2) {
    let dx = pt1.x - pt2.x;
    let dy = pt1.y - pt2.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
}
