export default class EmojiButton{
    loop
    constructor(payload){
        const {emotion,loop,element} = payload
        const id = `${emotion}_sliderEmojiButtons`
        const emotion_obj = loop.content[emotion]
        // const a = element.circle(emotion_obj.x,emotion_obj.y,28)
        const a = element.circle(payload.button.x,payload.button.y,28)
        .attr({ fill: 'black', stroke: 'black', opacity: 0, id: id});
        a.click((event)=>{
            loop.touch=false
            event.preventDefault();
            const payload = { click: event.target.id.split('_')[0] };
            loop.selectedContent = payload;
        })
        a.mouseover((event)=>{
            event.preventDefault();
            const payload = { mouseover: event.target.id.split('_')[0] };
            loop.currentContent = payload;
        })
        a.mouseout((event)=>{
            const payload = { mouseout: event.target.id.split('_')[0] };
            loop.currentContent = payload;
        })
        a.touchstart((event)=>{
            loop.touch=true
            loop.pollPositions()
        })
    }
}