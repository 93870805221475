import images from '../../../images/emojiring/*.png'
import EmojiButtons from './EmojiButtons.js';
export default class Ring {
  ring = document.getElementById('ring');
  snapElementMiddle;
  modalLock;
  emojiButtons;
  emojiSliders;
  loop;
  emojiActive = {};
  highLight = 35;
  loopType;
  _emojiButtons
  constructor(payload) {
    this.makeHousing();
    this.emojiButtons = payload;
    this.loop = payload.loop;
    this.snapElementMiddle = payload.snapElementMiddle;
    this.loop.on('click', (payload) => {
      this.resetBackgrouds();
      this.emojiActive[payload].attr({ opacity: 1 });
    });
    this.loop.on('ringReset', () => {
      this.resetBackgrouds();
    });
    this.loop.on('modalLock', (payload) => {
      document.getElementById('modalLock').style.zIndex = payload ? 20 : -1;
    });
    this.loop.on('update', () => {
      console.log('update')

      this.resetBackgrouds();
    });
    this.makeElements()
  }
  makeElements(){
    this.elements();
  }
  get dim(){
    return {
      l: '600',
      viewbox: '0 0 600 600' 
    };
  }

  get imageSource (){
    return     `${images['tracker']}`
    // return 'images/emojiring/tracker.png'
  }

  set emojiButtons(payload){
    this._emojiButtons =new EmojiButtons(payload)
    this._emojiButtons.ring0(30)
  }
  get emojiButtons(){return this._emojiButtons}


  resetBackgrouds() {
    for (let background in this.emojiActive) {
      this.emojiActive[background].attr({ opacity: 0 });
    }
  }

  makeHousing() {
    const bgContainer = document.createElement('div');
    bgContainer.style.width = '100%';
    bgContainer.style.height = '100%';
    bgContainer.style.zIndex = '2';
    bgContainer.id = 'bgContainer';

    bgContainer.style.position = 'absolute';
    bgContainer.style.top = '0';
    bgContainer.style.right = '0';

    const map = document.createElement('map');
    map.name = 'tracker';
    map.id = 'tracker';

    const img = document.createElement('img');
    img.id = 'trackerImage';
    img.src = this.imageSource;
    img.alt = 'Workplace';
    img.useMap = '#tracker';
    img.id = 'trackerImage';
    img.style.zIndex = '2';
    bgContainer.appendChild(img);
    bgContainer.appendChild(map);
    this.ring.appendChild(bgContainer);
  }

  elements() {
    const content = this.loop.content;
    for (let emoji in content) {
      this.emojiActive[emoji] = this.snapElementMiddle
        .circle(content[emoji].x, content[emoji].y, this.highLight)
        .attr({
          fill: content[emoji].background,
          strokeWidth: 4,
          stroke: 'black',
          opacity: 0,
        });
    }
  }
}
