export default class Hamburger{
  hamburger
  menu = document.getElementById('menu');
  menuModel

  constructor(payload){
    this.menuModel=payload.menuModel
    this.elements()
    this.menuModel.onMenuEvent('showMenu',(payload2)=>{
      if (payload2){
        document.getElementById('bar1').style.backgroundColor='black'
        document.getElementById('bar2').style.backgroundColor='black'
        document.getElementById('bar3').style.backgroundColor='black'
      }else{
        document.getElementById('bar1').style.backgroundColor='white'
        document.getElementById('bar2').style.backgroundColor='white'
        document.getElementById('bar3').style.backgroundColor='white'
      }
    })
  }
  
  elements(){
    const hamburgerContainer=document.createElement('div')
    hamburgerContainer.id='hamburgerContainer'
    const hamburger  = document.createElement('div')
    hamburgerContainer.style.zIndex='105'
    hamburgerContainer.style.display='flex'
    hamburgerContainer.style.flexDirection='row'
    // hamburgerContainer.style.maxWidth='100%'

    hamburger.id = 'hamburger'
    hamburger.style.margin='6px'
    hamburger.style.cursor='pointer'
    hamburger.style.display='block'
    hamburgerContainer.appendChild(hamburger)
    this.menu.appendChild(hamburgerContainer)
    hamburger.appendChild(this.rect('bar1'))
    hamburger.appendChild(this.rect('bar2'))
    hamburger.appendChild(this.rect('bar3'))
    hamburgerContainer.addEventListener('click', ()=>{
      this.menuModel.showMenu()
    })
  }

  rect(id){
    const rect = document.createElement('div')
    rect.id=id
    rect.style.width='35px'
    rect.style.height='5px'
    rect.style.backgroundColor='white'
    rect.style.margin='6px 0'
    return rect
  }
}