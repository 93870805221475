export default class StoryBox {
  loopScoop = document.getElementById('loopScoop');
  loop;
  storyBox;
  textArea;
  constructor(payload) {
    const { loop, attributes } = payload;
    if (attributes.settings.storyBox) {
      this.loop = loop;
      this.elelments();

      this.loop.on('lock', (isLocked) => {
        this.storyBox.disabled = !isLocked;
      });

      this.loop.on('mouseover', (emotion) => {
        this.render(emotion);
      });

      this.loop.on('click', (emotion) => {
        this.render(emotion);
      });

      this.loop.on('updateStory', (emotion) => {
        console.log('update')
        let content = this.loop.content[emotion];
        content.story = this.textArea.value;
        this.loop.content = content;
      });  
    }
  }
  render(emotion) {
    // console.log('emotion: ',emotion)
    let content = this.loop.content[emotion];
    this.textArea.value = content.story || '';
    this.textArea.style.zIndex = 20;
    this.textArea.style.resize = 'none';
    this.textArea.style.border = '1px solid black';
    this.textArea.style.borderRadius = '1em';
    this.textArea.style.margin = 'auto';

  }
  elelments() {
    this.storyBox = document.createElement('div');
    this.storyBox.style.display = 'flex';
    this.storyBox.style.order = 5;
    // this.storyBox.style.gridArea = 'storyBox';
    this.storyBox.style.justifyContent='center'
    this.storyBox.style.alignContent='center'
    this.storyBox.style.width = '100%';
    this.storyBox.style.marging = '0.1em';

    this.textArea = document.createElement('textarea');
    this.textArea.style.width='80%'
    this.textArea.name = '';
    this.textArea.id = 'textArea';
    this.textArea.cols = 18;
    this.textArea.rows = 6;
    this.textArea.placeholder = `OPTIONAL: Use this text box to explain your score.
      
This is not included in your report but is saved.`;
    this.textArea.style.display = 'flex';

    // this.textArea.style.justifyContent = 'center';
    this.textArea.style.zIndex = 20;
    this.textArea.style.resize = 'none';
    this.textArea.style.border = '1px solid black';
    this.textArea.style.borderRadius = '1em';
    this.textArea.style.margin = 'auto';



    this.storyBox.appendChild(this.textArea);
    this.loopScoop.appendChild(this.storyBox);
  }
}
