import EmojiContentMobileStoryBox from "./EmojiContentMobileStoryBox.js";
import RungMobileStoryBox from "./RungMobileStoryBox.js";

export default class StoryBoxMobileDisplay{
    // loopScoop = document.getElementById('loopScoop');
    content = document.getElementById('content')

    dummy
    dialog
    textArea
    loop
    emotion

    constructor(payload){
        this.loop=payload.loop
        this.elements()
        this.dummy.addEventListener('click',(event)=>{
            event.preventDefault()
            payload.loop.trigger('boxOn',this.emotion)
        })
        this.loop.on('click', (emotion) => {
          console.log('click then save: ',emotion)
          this.emotion=emotion
          this.dummy.innerHTML=this.loop.content[emotion].story ||''
        });
    }

    get loopScoop(){
        return document.getElementById('loopScoop');
      }
    
    elements(){
        const container = document.createElement('div')
        container.id='storyBoxMobile'
        container.style.order='5'
        container.style.width = "100%"
        container.style.display='flex'
        container.style.justifyContent='center'
        container.style.justifyItems='center'
        const dummy = document.createElement('div')
        dummy.style.width='18em'
        dummy.style.height='6em'
        dummy.style.border='1px solid black'
        dummy.style.borderRadius='1em'
        dummy.style.padding='1em'
        dummy.style.overflowY='scroll'
        container.appendChild(dummy)
        this.loopScoop.appendChild(container)
        this.dummy=dummy
    }
}