export default class Authorised {
  content = document.getElementById('content');
  constructor() {
    this.elements();
  }

  makeContainer(){
    const container = document.createElement('div');
    container.style.width = '80%';
    container.style.display = 'grid';
    container.style.gridTemplateColumns = '1fr 1fr';
    container.style.columnGap = '2em';
    container.style.justifyContent = 'center';
    return container
  }

  layout(payload){
    const {container,item1,item2}=payload
  }
  get text1(){
    return '<p>The emoji-ring represents over 20 years research and development for the whatmotivateslearning.com team. It is essentially a tracker and reflection tool but it is much more than that. The emoji-ring provides a tool set built around the most coherent framework of emotions available on the market. A framework which will give your learners the best chance to focus and grow their emotional mastery. A framework that has been developed at grass roots level throughout the UK within a broad range of educational settings and is supported by both theoretical and classroom resources.</p></p>Use the contact below to get a quote or arrange a trial.</p>';
  }
  get text2(){
    return "<p>The emoji-ring model is able to chart our emotions coherently, intuitively and organicaly. The emojiring model exoands as our understanding increases.  All emotions are essentially appraisals of experience in terms of goodness and badness, pleasure and pain. As the Emotion Ring is formed by such fundamental ways of valuing experience, it elicits meaning through differentiating discrete emotions - variations of goodness and badness - by  their purpose.  As a key to mastering emotions is interpreting their purposes, it's particularly apt for a framework to distinguish emotions by their purpose. The Ring in fact helps us to value all emotions as purposeful – as aids to survival.</p>"
  }
  get text3(){
    return "<p>All emotions are essentially appraisals of experience in terms of goodness and badness, pleasure and pain. As the Emotion Ring is formed by such fundamental ways of valuing experience, it elicits meaning through differentiating discrete emotions - variations of goodness and badness - by  their purpose.  As a key to mastering emotions is interpreting their purposes, it's particularly apt for a framework to distinguish emotions by their purpose. The Ring in fact helps us to value all emotions as purposeful - as aids to survival.</p>"
  }
  elements() {
    const landingPage = document.createElement('div');
    landingPage.style.display = 'flex';
    landingPage.style.alignItems = 'center';
    landingPage.style.justifyContent = 'center';
    landingPage.style.height = 'auto';
    landingPage.style.width = '100%';
    
    const container = this.makeContainer()
    

    const text = document.createElement('div');
    text.style.display = 'flex';
    text.style.flexDirection = 'column';
    text.style.alignItems = 'center';
    text.style.justifyContent = 'space-evenly';
    const textContent = document.createElement('div');
    textContent.style.alignItems = 'center';
    textContent.style.textAlign = 'center';
    textContent.style.justifyContent = 'center';
    // textContent.innerHTML = this.text1 + this.text2
    textContent.innerHTML = this.text1
    // textContent.innerHTML = this.text2
    // textContent.innerHTML = this.text3
    const emailButton = document.createElement('div');
    emailButton.style.textDecoration = 'none';
    emailButton.style.padding = '1em 2em';
    emailButton.style.width = '250px';
    emailButton.style.background = 'black';
    emailButton.style.color = 'white';
    emailButton.style.textAlign = 'center';
    emailButton.innerHTML = 'sales@whatmotivateslearning.com';
    emailButton.style.cursor = 'pointer';

    emailButton.addEventListener('click', () => {
      console.log('event');
      window.location.href =
        'mailto:sales@whatmotivateslearning.com?subject=emoji-ring trial';
    });

    text.appendChild(textContent);
    text.appendChild(emailButton);

    const image = document.createElement('div');
    const lineBreak = document.createElement('br')
    image.style.width='100%'
    image.style.display = 'flex';
    image.style.flexDirection = 'row'
    image.style.alignItems = 'center';
    image.style.justifyContent = 'center';
    const trackerJPG = document.createElement('img');
    trackerJPG.src = 'images/emojiring/trackerSmall.jpg';
    image.appendChild(trackerJPG);
    container.appendChild(text);
    container.appendChild(image);
    container.appendChild(lineBreak);
    landingPage.appendChild(container);

    this.content.appendChild(landingPage);

    this.content.removeChild(document.getElementById('trackerSection'));
  }
}
