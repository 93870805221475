import emojiRingImages from '../../../images/emojiring/*.png'
import thinkingImage from '../../../images/thinking/*.png'
export default class LoggedOut{
  constructor(payload){
    payload.loop.on('login',()=>{
      if (!payload.loop.authorized){
        document.getElementById('root').innerHTML=this.dangerouslySetInnerHTML()
      }
    })
    payload.loop.on('appErrorScreen',()=>{
      document.getElementById('root').innerHTML=this.dangerouslySetInnerHTML2()
    })
  }
  dangerouslySetInnerHTML2(){
    return `
    <div id="landingContainer">
      <div id='section1'>
        <div id='section1Content'>
          <br>
          <h1>We are sorry that the emotions-ring is not working just now. You can use the contact page to let us know and we will get back to you as soon as possible.</h1>
          <br>
        </div>
      </div>
    </div>`
  }

  dangerouslySetInnerHTML(){
    return `
    <div id="landingContainer">
      <div id='section1'>
        <div id='section1Content'>
          <br>
          <h1>The Emotion Ring Profile</h1>
          <br>
        </div>
      </div>
      <div id='section2'>
        <h2 style='text-align: center'>Emotional well-being is not so much about feeling good as being good at feeling</h2>
        <p>And being good at feeling requires knowledge of our emotions and the emotions process. Emotions are a result of the value we attribute to people and things. Sorting them comes down to differentiating different forms of goodness and badness. What moulds our sense of goodness and badness into the full range of emotions? The Emotion Ring offers an answer. It’s formed from a pair of appraisals, core to human experience, namely:</p>
        <p><b>Valuing ourselves</b> - feeling good through appraisal of ourselves as a result of getting ahead, with a focus on our importance, achievements, rights.</p>
        <p><b>Valuing others</b> - feeling good through appraisal of ourselves as a result of getting along, respecting what other people want and need, with a focus on responsibilities.</p>
        <p>The Ring organises emotions by their purpose in terms of the balance between valuing self and valuing others. The appraisals are represented as spirals working in partnership to form the Emotion-Ring. In the upper half of the ring the appraisals counterbalance each other - the emotions are uplifting. The lower half charts an increasing imbalance, where one way of valuing outweighs the other, causing emotions that lead us to struggle. The Ring divides into Quadrants, each with a distinct purpose.</p>
        <br>
        <h2 style='text-align: center'>The Emotion Quadrants</h2>
        <div class='valenceContinua'>
          <img src=${emojiRingImages['emotionsQuadrants']} alt='The Emotions Quadrants'>
        </div>
        <p>The quadrants are partitioned into 4 Clusters, each with their own purpose. The Emotion Ring consists of 8 pairs of clusters that form 16 scales. The Profile invites us to rate our current feelings on these scales.</p>
        <br>
        <h2 style='text-align: center'>The Emotion Clusters</h2>
        <div class='valenceContinua'>
          <img src=${thinkingImage['Fig_4']} alt='The Valence Continua'>
        </div>
        <p>The profile provides a quick yet comprehensive pictorial journaling of emotions over time. The chart provides an objective framework, to analyse how an individual is feeling and thinking. For the ‘emotion coach’, it’s an attuning tool that facilitates focused dialogue. The profile cuts to the chase, while at the same time giving a big picture perspective and sets the context for a rich conversation about the individual’s emotional experience.  Associated resources help individuals curate their emotional well-being.</p>
        </div>
        <h2 style='text-align: center'>You can arrange a free one month trial from the contact page.</h2>
        </div>`
      }
    }