import Rung from './Rung.js'
export default class RungMobile extends Rung{
    constructor(payload){
        super(payload)
    }
    // renderRung(payload){
    //   this.rung.innerHTML=payload
    // }
    get loopScoop(){
        return document.getElementById('emojiContent')
    }
    get rungContainerID(){
        return 'rungContainerMobile'
      }
      get rungID(){
        return 'rungMobile'
      }
      get order(){
        return '2'
      }
      elements() {
        const rungContainer = document.createElement('div');
        rungContainer.id = this.rungContainerID;
        rungContainer.style.order = '3'
        rungContainer.style.display='flex'
        rungContainer.style.width='auto'
        // rungContainer.style.margin='0.25em'
        rungContainer.style.alignItems='center'
        rungContainer.style.justifyContent='center'
    
        const rung = document.createElement('h1');
        rung.id=this.rungID
        rung.style.fontSize = '50px';
        rung.style.margin = '0.3em 0';
        rung.style.display = 'flex';
        rung.style.justifyContent = 'center';
        rungContainer.appendChild(rung)
        this.loopScoop.appendChild(rungContainer);
        this.rung = rung;
      }
    
    
    
}