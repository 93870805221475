export default class Login {
  menuModel;
  menu = document.getElementById('menu');
  rhs
  state='login'

  constructor(payload) {
    this.menuModel = payload.menuModel;
    this.menuModel.onMenuEvent('loggedIn', () => {
       this.rhs.innerHTML = 'logout';
    });
    this.menuModel.onMenuEvent('loggedOut', () => {
        this.rhs.innerHTML = 'login';
    });
    this.state=this.menuModel.authorised?'logout':'login'
    this.menu.appendChild(this.elements());
  }

  elements() {
    const rhs = document.createElement('div');
    const rhsContainer = document.createElement('div')
    rhsContainer.id = 'rhsContainer';
    rhs.id = 'logInOutAnchor';
    rhs.style.textDecoration = 'none';
    rhs.style.color = 'white';
    rhs.style.cursor = 'pointer';
    rhs.style.margin='0.5rem'
    rhs.style.textAlign='center'
    rhs.innerHTML=this.state
    rhs.addEventListener('click', (e) => {
      this.loginEvent(e)
    });
    this.rhs=rhs
    rhsContainer.appendChild(rhs)
    return rhsContainer;
  }

  loginEvent(payload){
    if(this.menuModel.authorised){
      this.menuModel.logOut()
    }else{
      this.menuModel.logIn()
      // this.menuModel.logOut()
    }
  }
}
