import images from '../../../images/menu/*.png';
export default class Masthead{
  menu = document.getElementById('menu');
  constructor(payload){
    const mastHead = document.createElement('div');
    mastHead.classList.add('mastHead')
    const mastHead_image_white = document.createElement('img');
    const mastHead_image_black = document.createElement('img');
    mastHead_image_black.classList.add('mastHead_image_relative')
    mastHead_image_white.classList.add('mastHead_image_absolute')
    mastHead_image_white.src = `${images['loop_white']}`;
    mastHead_image_black.src = `${images['loop_black']}`;
    // mastHead_image_white.src = '../images/menu/loop_white.png';
    // mastHead_image_black.src = '../images/menu/loop_black.png';
    mastHead.appendChild(mastHead_image_black);
    mastHead.appendChild(mastHead_image_white);
    this.menu.appendChild(mastHead)
    payload.menuModel.onMenuEvent('showMenu',(payload2)=>{
      if (payload2){
        mastHead_image_white.classList.add('transitionOut')
        mastHead_image_black.classList.add('transitionIn')
        mastHead_image_white.classList.remove('transitionIn')
        mastHead_image_black.classList.remove('transitionOut')
      }else{
        mastHead_image_black.classList.add('transitionOut')
        mastHead_image_white.classList.add('transitionIn')
        mastHead_image_black.classList.remove('transitionIn')
        mastHead_image_white.classList.remove('transitionOut')
      }
    })
  }
}