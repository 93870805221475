import Track from "./Track.js";
export default class TrackMobile extends Track{
    radius = 124;
    constructor(payload){
        super(payload)
    }

    get xyC(){
        return 170;
    }
}