export default class EmojiButtons {
  controller;
  radius;
  loop;
  constructor(models) {
    this.loop = models.loop;
    this.radius = 30;
  }
  
  ring0(radius) {
    const content = this.loop.content;
    for (let emoji in content) {
      const areaID = `${content[emoji].emotion}_B0`;
      const coords = `${content[emoji].x},${content[emoji].y},${radius}`;
      let area = document.createElement('area');
      area.shape = 'circle';
      area.coords = coords;
      area.href = '#';
      area.id = areaID;
      document.getElementById('tracker').appendChild(area);

      $(`#${areaID}`).on('click', (event) => {
        event.preventDefault();
        const payload = { click: event.target.id.split('_')[0] };
        this.loop.selectedContent = payload;
      });
      $(`#${areaID}`).on('mouseover', (event) => {
        event.preventDefault();
        const payload = { mouseover: event.target.id.split('_')[0] };
        this.loop.currentContent = payload;
      });
      $(`#${areaID}`).on('mouseout', (event) => {
        const payload = { mouseout: event.target.id.split('_')[0] };
        this.loop.currentContent = payload;
      });
    }
  }
}
