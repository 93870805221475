export default class AppMenu {
  root = document.getElementById('root');
  constructor(payload) {
    this.elements();
  }
  get fontSize(){
    return '24px'
  }

  get nameInputHeight(){
    return '80px'
  }

  get menuWidth(){
    return '800px'
  }
  elements() {
    const appMenuContainer = document.createElement('div');
    appMenuContainer.id='appMenuContainer'
    appMenuContainer.style.display = 'flex';
    appMenuContainer.style.flexDirection = 'row';
    appMenuContainer.style.justifyContent = 'center';
    appMenuContainer.style.marginBottom = '1rem';
    appMenuContainer.style.order = 1;

    const controls = document.createElement('div')
    controls.style.width=this.menuWidth
    controls.style.display='flex'
    controls.style.justifyContent='center'

    const controlsContainer = document.createElement('div');
    controlsContainer.id='controlsContainer'
    controlsContainer.style.width = '100%';
    controlsContainer.style.display = 'grid';
    controlsContainer.style.gridTemplateColumns = '1fr 1fr 1fr';
    controlsContainer.style.justifyContent = 'center';
    controlsContainer.style.alignItems = 'center';

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = 'fileInput';
    fileInput.name = 'file[]';
    fileInput.style.display = 'none';

    const open = document.createElement('button');
    open.id = 'p1';
    open.className = 'rateButE';
    open.innerHTML = 'Open';
    open.style.fontSize = this.fontSize;

    const save = document.createElement('button');
    save.id = 'except';
    save.className = 'rateButE';
    save.innerHTML = 'Save';
    save.style.fontSize = this.fontSize;

    const name = document.createElement('input');
    name.type = 'text';
    name.placeholder = 'Name';
    name.id = 'nameInput';
    name.style.fontSize = this.fontSize;
    name.style.border = 'none';
    name.style.height = this.nameInputHeight
    name.style.textAlign = 'center';

    appMenuContainer.appendChild(fileInput);
    controlsContainer.appendChild(open);
    controlsContainer.appendChild(save);
    controlsContainer.appendChild(name);
    controls.appendChild(controlsContainer)
  
    appMenuContainer.appendChild(controls);
    this.root.appendChild(appMenuContainer);
  }
}
