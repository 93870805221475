import EmojiContentMobileStoryBox from "../Bar/Components/EmojiContentMobileStoryBox.js";
// import RungMobileStoryBox from "./RungMobileStoryBox.js";

export default class StoryBoxMobile{
    // loopScoop = document.getElementById('loopScoop');
    content = document.getElementById('content')

    dummy
    dialog
    textArea
    loopDialogContent
    backButton
    wrapper
    start
    offset='0px'
    top
    emotion

    constructor(payload){
        this.dialogElements(payload)
        let fixPosition=0
        payload.loop.on('boxOn',(top)=>{
            this.textArea.value= payload.loop.content[this.emotion].story ||''
            this.top=top
            this.start =this.wrapper.getBoundingClientRect().top
            this.dialog.style.zIndex='301'
            this.dialog.style.opacity='1'
            this.textArea.focus();
            this.dialog.addEventListener("touchmove", function (e) {
                e.preventDefault();
            }, { passive: false });
        })
        const show=()=>{
            const newPosition = this.wrapper.getBoundingClientRect().top-this.start
            if (newPosition < -1) {
              // add a margin to show the toolbar
              fixPosition = Math.abs(newPosition); // this is new position we need to fix the toolbar in the display
              // set the margin to the new fixed position
              this.offset=fixPosition + "px"
              this.dialog.style["margin-top"] = this.offset;
            }
        }
        window.addEventListener('scroll',show)
        // this.textArea.addEventListener('blur',()=>{
        //     console.log('blur')
        //   })
        payload.loop.on('click', (emotion) => {
            this.emotion=emotion
        })
    }

    get loopScoop(){
        return document.getElementById('loopScoop');
      }

    dialogElements(payload){
        const wrapper = document.createElement('div')
        wrapper.style.display='sticky'
        wrapper.style.top='0px'
        wrapper.style.height='60px'
        wrapper.style.width='100%'

        const dialog = document.createElement('div')

        dialog.id='storyBoxDialog'
        // dialog.style.height='600px'
        wrapper.appendChild(dialog)

        const backButton = document.createElement('div')
        backButton.style.order='3'
        backButton.style.background='white'
        backButton.style.width='120px'
        backButton.style.height='40px'
        backButton.style.fontSize='20px'
        backButton.style.borderRadius='1em'
        backButton.style.border='1px solid #bdc3c7'
        backButton.style.cursor='pointer'
        backButton.style.display='flex'
        backButton.style.alignItems='center'
        backButton.style.alignContent='center'
        backButton.style.justifyContent='center'
        const back=document.createElement('div')
        back.style.margin='0'
        back.innerHTML='BACK'
        backButton.addEventListener('click',()=>{
            let content = payload.loop.content[this.emotion];
            content.story = this.textArea.value;
            payload.loop.content = content;
            this.dialog.style.zIndex='-1'
            this.dialog.style.opacity='0'
            window.scrollTo(0,0)
            document.body.scrollTop = 0;
            payload.loop.trigger('click',this.emotion)
        })
        backButton.appendChild(back)
        

        const loopDialogContent = document.createElement('div')
        loopDialogContent.id='loopDialogContent'
        loopDialogContent.style.width='auto'
        loopDialogContent.style.height='auto'

        loopDialogContent.style.margin='1em 0'
        loopDialogContent.style.marginTop='1em'
        loopDialogContent.style.display='flex'
        // loopDialogContent.style.position='fixed'
        // loopDialogContent.style.top='0px'
        loopDialogContent.style.alignItems='center'
        loopDialogContent.style.alignContent='center'
        loopDialogContent.style.justifyContent='center'
        loopDialogContent.style.order='2'
        
        
        const textArea = document.createElement('textarea');
        textArea.id='storyBoxMobileTextArea'
        textArea.style.width='80%'
        // textArea.style.position='fixed'
        // textArea.style.top='6em'
        // textArea.style.order='2'
        textArea.name = '';
        textArea.id = 'textArea';
        textArea.cols = 18;
        textArea.rows = 13;
        textArea.placeholder = `OPTIONAL: Use this text box to explain your score. This is not included in your report but is saved.`;
        textArea.style.display = 'flex'
        textArea.style.zIndex = 20;
        textArea.style.resize = 'none';
        textArea.style.border = '1px solid black';
        textArea.style.borderRadius = '1em';
        textArea.style.margin = '1em';
        textArea.style.overscrollBehavior='contain'
        textArea.style.order='1'
        textArea.style.overflowY='scroll'
        
        
        dialog.appendChild(loopDialogContent)
        dialog.appendChild(textArea)
        dialog.appendChild(backButton)
        
        // this.content.appendChild(dialog)
        document.body.appendChild(wrapper)
        this.dialog=dialog
        new EmojiContentMobileStoryBox(payload)
        this.textArea= textArea
        this.loopDialogContent=loopDialogContent
        this.backButton=backButton
        this.wrapper=wrapper
    }
}