export default class Attributes {
  _mute=false
  _isIOS=false
  constructor(events) {
    this.events = events;
    this.on = this.events.on;
    this.trigger = this.events.trigger;
  }
  dashboard = {
    fname: '',
    loopType: 'sliders',
    clusterEmotion: false,
    storyBox: true,
    secured: false,
    password: '',
    menu: false,
  };
  get settings() {
    return this.dashboard;
  }
  set settings(theSet) {
    const key = Object.keys(theSet)[0];
    this.dashboard[key] = theSet[key];
    switch (key) {
      case 'menu':
        this.trigger('menu', theSet);
      default:
        this.trigger('change', theSet);
    }
  }
  get mute(){
    return this._mute;
  }
  set mute(payload){
    this._mute=payload
    this.trigger('mute')
  }
  get isIOS(){
    return this._isIOS
  }
  set isIOS(payload){
    this._isIOS = payload
  }
}
