import DialogOK from "./DialogOK.js"
import DialogProcedeCancel from "./DialogProcedeCancel.js"
import DialogResourceServer from "./DialogResourceServer.js"
import images from '../../../../images/menu/*.png'

export default class DialogController{
  dialogContainer
  dialogOk
  dialogProcedeCancel
  dialogResourceServer
  constructor(payload){
    const {menuModel}= payload
    const dialog = this.elements()
    this.dialogOk = new DialogOK(payload)
    this.dialogProcedeCancel = new DialogProcedeCancel(payload)
    this.dialogResourceServer = new DialogResourceServer(payload)
    
    menuModel.onMenuEvent('dialogUp',(payload2)=>{
      this.hoist(payload2)
      dialog.style.zIndex='200'
      dialog.style.opacity='1'
    })
    menuModel.onMenuEvent('dialogDown',(payload2)=>{
      dialog.style.zIndex = '-1';
      dialog.style.opacity = '0';
    })
  }
  hoist(payload){
    let dc = {
      action: 'dismis',
      info: payload.content,
      dialog: this.dialogOk
    }
    switch(payload.type){
      case 'popup':
        this.makeDialog(dc)
      break;
      case 'saveFail':
        this.makeDialog(dc)
      break;
      case 'logout':
        dc.dialog=this.dialogProcedeCancel
        dc.action='logOutConfirmed'
        this.makeDialog(dc)
      break;
      case 'authError':
        dc.dialog=this.dialogProcedeCancel
        dc.action='authErrorLogOut'
        this.makeDialog(dc)
      break;
      case 'criticalError':
        dc.action='criticalError'
        this.makeDialog(dc)
      break;
      case 'resourceServer':
        dc.dialog=this.dialogResourceServer
        this.makeDialog(dc)
      break;
      default:
    }
  }

  makeDialog(payload){
    console.log('payload: ',payload)
    payload.dialog.content={
      action: payload.action,
      info: payload.info
    }
    this.dialogContainer.replaceChildren(payload.dialog.content)
  }

  elements(){
    const dialog = document.createElement('div')
    dialog.id='dialog'
    dialog.style.zIndex='-1'
    dialog.style.opacity='0'
    const dialogLogo = document.createElement('div')
    dialogLogo.id='dialogLogo'
    const img = document.createElement('img')
    // img.src='./images/menu/emotions-ring-logo.png'
    img.src=`${images['emotions-ring-logo']}`
    img.alt='logo'
    dialog.appendChild(img)

    const dialogContainer = document.createElement('div')
    dialogContainer.style.maxWidth='340px'
    dialogContainer.style.textAlign='center'
    dialog.appendChild(dialogLogo)
    dialog.appendChild(dialogContainer)
    document.getElementById('content').appendChild(dialog)
    this.dialogContainer=dialogContainer
    return dialog
  }
}