import AppMenu from "./AppMenu.js";
export default class AppMenuMobile extends AppMenu{
    get fontSize(){
        return '16px'
    }
    
    get nameInputHeight(){
      return '40px'
    }

    get menuWidth(){
      return '100%'
    }
  
  

}