import RungMobile from "./RungMobile.js";
export default class RungMobileStoryBox extends RungMobile{
    constructor(payload){
        super(payload)
    }
    get loopScoop(){
        return document.getElementById('emojiContentStoryBox')
    }

    get rungContainerID(){
        return 'rungContainerStoryBoxMobile'
    }

    get rungID(){
        return 'rungStoryBoxMobile'
    }
}