export default class Key {
  _key
  constructor(payload){}

  get key(){
    if (!this._key){
      this._key=''
      for (let i = 0; i < 8; i++) {
        if (i / 2 - Math.floor(i / 2) > 0) {
            this._key += this.get_let();
        } else {
            this._key += this.get_num();
        }
      }

    }
    return this._key
  }

  set key(payload){
    this._key=payload
  }

  get_let() {
      return String.fromCharCode(97 + Math.round(Math.random() * 25));
  }

  get_num() {
      return new String(Math.round(Math.random() * 9));
  }
}