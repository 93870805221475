import BarController from '../view/Bar/BarController.js';
import BarControllerMobile from '../view/Bar/BarControllerMobile.js';
import RingController from '../view/Ring/RingController.js';
import RingControllerMobile from '../view/Ring/RingControllerMobile.js';
import ReportingController from './Reporting/ReportingController.js';
import DialogController from './Dialog/DialogController.js';
import AppMenu from './Menus/AppMenu.js';
import AppMenuMobile from './Menus/AppMenuMobile.js';
import Authorised from './Authorised/Authorised.js';
import AuthorisedMobile from './Authorised/AuthorisedMobile.js';
import SiteMenu from './Menus/SiteMenu.js';

import Attributes from '../model/Attributes.js';
import Loop from '../model/Loop.js';
import Eventing from '../model/Eventing.js';
// import Footer from './Footer/Footer.js';
import AccessibilityController from './Accessibility/AccessibilityController.js';
import Sound from './Sound/SoundController.js';
import StoryBoxMobile from './Dialog/StoryBoxMobile.js';
import StoryBox from './Bar/Components/StoryBox.js';
import Mute from '../view/Sound/Mute.js'
import LoggedOut from './LoggedOut/LoggedOut.js';

export default class MainControllerHoist {
  root = document.getElementById('root')
  loop
  attributes
  payload

  constructor(payload) {

    payload.menuModel.onMenuEvent('appUp',(payload2)=>{
      console.log('appUp payload: ',payload2)
      this.loop.emotion.update(payload2)
      document.getElementById('landingContainer').remove();
      this.loop.authorisationOffBlocks();
      this.loop.storeState();
    })

    const events = new Eventing();
    
    this.loop= new Loop({
      events: events,
      menuController: payload.menuController
    })

    this.attributes=new Attributes(events)
    
    this.payload = {
      loop: this.loop,
      attributes: this.attributes
    }

    new DialogController(this.payload);
    
    this.loop.on('popup',(content)=>{
      payload.menuModel.popup(content)
    })

    // this.loop.on('criticalError',(error)=>{
    //   console.log('critical error main controller hoist.')
    //   const popup = {
    //     type: 'criticalError',
    //     content: `Sorry, there is a problem just mow with this page so we can't show you what you requested.`,
    //     action: 'criticalError'
    //   }
  
    //   payload.menuModel.popup(popup)
    // })

    new LoggedOut(this.payload)
    
    document.body.addEventListener("scroll", ()=>{
      this.loop.pollPositions()
    }, false);
    
    this.loop.on('login', (logged) => {
      if (logged) {
        this.loop.on('media',(media_payload)=>{
          document.getElementById('loop').style.flexDirection = this.loop.media.portrait?'column':'row'
        })
        this.components()
      }
    });

    const emojiring = sessionStorage.getItem('emojiring')
    emojiring?this.landing(JSON.parse(emojiring)):this.loop.authorisationOnBlocks();
    
    this.loop.loadState()
    
    this.preload();
  }

  landing(payload){
    this.loop.emotion.update(payload.emotion)
    this.loop.authorisationOffBlocks();
  }

  scroll(payload){
    console.log('scroll end: ',payload)
  }

  components(){
    if (this.loop.media.available>=this.loop.viewportPoint && !this.loop.resetForRotation){
      this.elements()
      new AppMenu(this.payload);
      new BarController(this.payload);
      new RingController(this.payload);
    }else{
      // console.log('iOS: ',this.iOS())
      if(this.iOS()){
        this.attributes.isIOS=true
        this.attributes.isIOS=false
        new StoryBoxMobile(this.payload)
      }
      this.mobileElements()
      new AppMenuMobile(this.payload);
      new BarControllerMobile(this.payload);
      new RingControllerMobile(this.payload);
    }
    // new StoryBox(this.payload)
    new ReportingController(this.payload);
    // new DialogController(this.payload);
    // new Footer(this.payload)
    new Sound(this.payload)
    new AccessibilityController(this.payload)
    const mute = new Mute(this.payload)
    const rhsContainer = document.getElementById('rhsContainer')
    rhsContainer.appendChild(mute)
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  elements(){
    const loop = document.createElement('div')
    loop.id='loop'
    const ring = document.createElement('div')
    ring.id='ring'
    loop.appendChild(ring)
    this.root.appendChild(loop)
    this.baseElement()
  }

  mobileElements(){
    const l='340px'
    const loop = document.createElement('div')
    loop.id='loop'
    loop.style.display='flex'
    loop.style.flexDirection=this.loop.media.portrait ? 'column' : 'row'
    loop.style.alignItems='center'
    loop.style.order='2'
    
    const ring = document.createElement('div')
    ring.id='ring'
    ring.style.width=l
    ring.style.height=l
    loop.appendChild(ring)
    this.root.appendChild(loop)
    this.baseElement()
  }
  baseElement(){
    const baseElements = document.createElement('div')
    baseElements.id='baseElements'
    baseElements.style.display='flex'
    baseElements.style.width='100%'
    baseElements.style.height='auto'
    baseElements.style.allignItems='center'
    baseElements.style.order='3'
    baseElements.style.justifyContent='center'
    baseElements.style.margin='1rem 0'
    this.root.appendChild(baseElements)
  }

  preload(){
    // Object.keys(this.loop.emotion.emotion).map(x => `./images/emojiring/${x}.png`).forEach((url)=>{
    //   const img = new Image()
    //   img.src=url
    // })
  }

  dismis(){
    this.loop.modal=false
  }

  leave(payload){
    if (sessionStorage.getItem('emojiring')){
      this.loop.storeState()
    }
    window.location.href=payload
  }

  emojiringDownUp(){
    try {
      document.getElementById('loop').remove();
      document.getElementById('baseElenents').remove();
      document.getElementById('appMenuContainer').remove();
      
    } catch (error) {
      console.log('element remove error: ',error)
    }

    this.loop.trigger('appErrorScreen')
    console.log('AppDownUp')
  }

  devs(){
    // this.loop._menuController.menuModel.triggerMenuEvent('dialogUp',{content: 'Getting an empty ring...', type: 'resourceServer'})
    // this.loop._menuController.menuModel.triggerMenuEvent('dialogDown')
    this.loop.emotion.update(this.loop.emotion.emotion)
    document.getElementById('landingContainer').remove();
    
    this.loop.authorisationOffBlocks();
    this.loop.storeState();
    // console.log('devs: ',this.loop.emotion.emotion)
  }
}