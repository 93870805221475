import BarController from "./BarController.js";
import ContentMobile from './ContentMobile.js';
import Instructions from './Instructions.js';

export default class BarControllerMobile extends BarController{
constructor(payload){

    super(payload)
}
elements(payload){
    const loopViewer = document.createElement('div')
    loopViewer.id='loopViewer'
    loopViewer.style.display='flex'
    loopViewer.style.width='340px'
    loopViewer.style.height='340px'
    const loopContent = document.createElement('div')
    loopContent.id='loopContent'
    const loopScoop = document.createElement('div')
    loopScoop.id='loopScoop'
    loopContent.appendChild(loopScoop)
    loopViewer.appendChild(loopContent)
    loop.appendChild(loopViewer)
  }

components(payload){
    new ContentMobile(payload);
    new Instructions(payload);

}

}