import MenuEventing from './model/MenuEventing.js'
import MenuAttributes from './model/MenuAttributes.js'
import MenuModel from './model/MenuModel.js'
import MenuBar from './view/MenuBar.js'
import Footer from './view/Footer.js'
import Modal from './view/Modal.js'
import MainControllerHoist from '../../emojiring/view/MainControllerHoist.js'
// import MainControllerHoist from '../../emojiring/src/view/MainControllerHoist.js'
import DialogController from './view/Dialog/DialogController.js'
import Menu from './view/Menu.js'
import Contact from './view/Contact.js'
import Analytics from './view/Analytics.js'
// import SiteMenu from './view/SiteMenu.js'
export default class MenuController{
  
  app
  _criticalError = false
  menuModel

  constructor(appPayload){

    const menuAttributes = new MenuAttributes()
    const menuEvents = new MenuEventing()
    this.menuModel = new MenuModel(menuEvents)
    const payload = {
      menuModel: this.menuModel,
      menuAttributes: menuAttributes
    }
    
    new MenuBar(payload)
    new Footer(payload)
    new Menu(payload)
    new DialogController(payload)
    switch(this.menuModel.path){
      case 'emojiring':
        payload.menuController=this
        this.app = new MainControllerHoist(payload)
        break;
        case 'contact':
        new Contact()
      default:
    }

    this.menuModel.onMenuEvent('dismis',()=>{
      this.app.dismis()
    })
    
    
    this.menuModel.onMenuEvent('menu',(payload)=>{
      switch(this.menuModel.path){
        case 'emojiring':
          console.log('tenk')
          this.app.leave(payload)
        break;
        default:
          window.location.href=payload       
        }
    })
 
    if (!localStorage.getItem('analyticsConsent')){
      new Analytics(payload)
    }

    this.menuModel.onMenuEvent('emojiRingDown',()=>{
      this.app.emojiringDownUp()
    })
    this.menuModel.onMenuEvent('criticalError',()=>{
      this.app.emojiringDownUp()
    })
    this.menuModel.onMenuEvent('contentServer',()=>{
      console.log('content server')
    })
    this.menuModel.authorised= false
    // this.menuModel.authorised= true
    if(this.app){
      // this.app.devs()
    }
  }
  set criticalError(payload){
    const popup = {
      type: 'criticalError',
      content: `Sorry, there has been a critical error on this page so we can't show you what you requested.`,
      action: 'criticalError'
    }

    
    this.menuModel.popup(popup)

    console.log('menuController critical error set')
    this._criticalError=payload
  }
  get criticalError(){
    return this._criticalError
  }
}