import Adventurous from './Emoji/Adventurous.js';
import Annoyed from './Emoji/Annoyed.js';
import Caring from './Emoji/Caring.js';
import Determined from './Emoji/Determined.js';
import Down from './Emoji/Down.js';
import Friendly from './Emoji/Friendly.js';
import Grudging from './Emoji/Grudging.js';
import Kind from './Emoji/Kind.js';
import Mean from './Emoji/Mean.js';
import Proud from './Emoji/Proud.js';
import Scared from './Emoji/Scared.js';
import Sorry from './Emoji/Sorry.js';
import Thankful from './Emoji/Thankful.js';
import Thrilled from './Emoji/Thrilled.js';
import Ungrateful from './Emoji/Ungrateful.js';
import Useless from './Emoji/Useless.js';
import Tracker from './Tracker.js';
export default class ImageServer {
  int_adventurous = new Adventurous();
  int_annoyed = new Annoyed();
  int_caring = new Caring();
  int_determined = new Determined();
  int_down = new Down();
  int_friendly = new Friendly();
  int_grudging = new Grudging();
  int_kind = new Kind();
  int_mean = new Mean();
  int_proud = new Proud();
  int_scared = new Scared();
  int_sorry = new Sorry();
  int_thankful = new Thankful();
  int_thrilled = new Thrilled();
  int_ungrateful = new Ungrateful();
  int_useless = new Useless();
  int_tracker = new Tracker();
  constructor() {}
  get tracker() {
    return this.int_tracker;
  }
  get adventurous() {
    return this.int_adventurous;
  }
  get annoyed() {
    return this.int_annoyed;
  }
  get caring() {
    return this.int_caring;
  }
  get determined() {
    return this.int_determined;
  }
  get down() {
    return this.int_down;
  }
  get friendly() {
    return this.int_friendly;
  }
  get grudging() {
    return this.int_grudging;
  }
  get kind() {
    return this.int_kind;
  }
  get mean() {
    return this.int_mean;
  }
  get proud() {
    return this.int_proud;
  }
  get scared() {
    return this.int_scared;
  }
  get sorry() {
    return this.int_sorry;
  }
  get thankful() {
    return this.int_thankful;
  }
  get thrilled() {
    return this.int_thrilled;
  }
  get ungrateful() {
    return this.int_ungrateful;
  }
  get useless() {
    return this.int_useless;
  }
}
