import Ring from './Ring.js';
import Sliders from './Sliders.js';
import Tracks from './Tracks.js';
import Snap from 'snapsvg'
export default class RingController {
  ring = document.getElementById('ring');
  snapElementMiddle;
  modalLock;
  dimensions;
  constructor(payload) {
    this.dimensions=this.dim
    this.svgElements();
    payload.loop.on('modalLock', (payload) => {
      document.getElementById('mlContainer').style.zIndex = payload ? 100 : -1;
    });
    payload.snapElementMiddle = this.snapElementMiddle;
    this.loopType(payload)
  }

  get dim(){
    return {
      l: '600',
      viewbox: '0 0 600 600' 
    };
  }

  loopType(payload){
    switch (payload.attributes.dashboard.loopType) {
      case 'sliders':
        new Sliders(payload);
        new Tracks(payload);
        break;
      default:
        new Ring(payload);
        break;
    }
    
  }
  
  svgElements() {
    const mlContainer = document.createElement('div');
    mlContainer.style.width = '100%';
    mlContainer.style.height = '100%';
    mlContainer.style.zIndex = '-1';
    mlContainer.id = 'mlContainer';
    mlContainer.style.position = 'absolute';
    mlContainer.style.top = '0';
    mlContainer.style.right = '0';

    const modalLock = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    modalLock.setAttribute('width', this.dimensions.l);
    modalLock.setAttribute('height', this.dimensions.l);
    modalLock.setAttribute('viewbox', this.dimensions.viewbox);
    modalLock.setAttribute('preserveAspectRatio', 'none');
    modalLock.setAttribute('id', 'modalLock');
    mlContainer.appendChild(modalLock);

    const semContainer = document.createElement('div');
    semContainer.style.width = '100%';
    semContainer.style.height = '100%';
    semContainer.style.zIndex = '2';
    semContainer.id = 'semContainer';

    const snapElementMiddle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    snapElementMiddle.setAttribute('width', this.dimensions.l);
    snapElementMiddle.setAttribute('heigth', this.dimensions.l);
    snapElementMiddle.setAttribute('viewbox', this.dimensions.viewbox);
    snapElementMiddle.setAttribute('preserveAspectRatio', 'none');
    snapElementMiddle.setAttribute('id', 'snapElementMiddle');
    snapElementMiddle.style.zIndex = '1';

    semContainer.appendChild(snapElementMiddle);
    this.ring.appendChild(semContainer);
    this.ring.appendChild(mlContainer);
    this.snapElementMiddle = Snap('#snapElementMiddle');
    this.modalLock = Snap('#modalLock');

    this.snapElementMiddle
      .rect(0, 0, Number(this.dimensions.l), Number(this.dimensions.l), 0, 0)
      .attr({ fill: 'white', stroke: 'white' });

    this.modalLock
      .rect(0, 0, Number(this.dimensions.l), Number(this.dimensions.l), 0, 0)
      .attr({ fill: 'white', stroke: 'white', opacity: 0.9 });
  }
}
